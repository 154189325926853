import React, { useEffect, useState } from "react";
import PocketBase from "pocketbase";
import Base from "./Base";
import Lightbox from "react-image-lightbox"; // Import Lightbox
import "react-image-lightbox/style.css"; // Štýly pre Lightbox
import { Helmet } from "react-helmet";
import SafariFeatured from "./SafariFeatured";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const Home = () => {
  const [gallery, setGallery] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // Lightbox state
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch gallery images
        const galleryRecords = await pb.collection("gallery").getFullList({
          sort: "-created",
        });
        setGallery(galleryRecords);

        // Fetch reviews
        const reviewRecords = await pb.collection("reviews").getFullList({
          sort: "-created",
        });
        setReviews(reviewRecords);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Change the current review every 5 seconds
  useEffect(() => {
    if (reviews.length > 1) {
      const interval = setInterval(() => {
        setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [reviews]);

  // Gallery images URLs
  const images = gallery.map(
    (item) =>
      `${process.env.REACT_APP_POCKETBASE_URL}/api/files/gallery/${item.id}/${item.image}`
  );

  const checkImageLoaded = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => reject(false);
      img.src = url;
    });
  };

  const openLightbox = async (index) => {
    try {
      const isLoaded = await checkImageLoaded(images[index]);
      if (isLoaded) {
        setCurrentImageIndex(index);
        setIsOpen(true);
      }
    } catch {
      console.error("Image not loaded:", images[index]);
    }
  };
  const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };
  
  useEffect(() => {
    preloadImages(images); // Preload obrázkov po načítaní galérie
  }, [images]);
  
  const closeLightbox = () => {
    setIsOpen(false);
  };

  const moveNext = () => {
    if (images.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  const movePrev = () => {
    if (images.length > 0) {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
    }
  };
  
  return (
    <>
    <Helmet>
        <title>IconRedSea | Home</title>
      </Helmet>
    <Base>
      {/* Hero Section */}
      <header
        className="relative bg-cover bg-center text-white py-28"
        style={{
          backgroundImage:
            "url('/IMG_3412-1.jpeg')",
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/30 to-transparent"></div>
        <div className="relative container mx-auto text-center">
          <h1 className="text-5xl font-extrabold mb-4 drop-shadow-lg">
            Discover the Beauty of Diving
          </h1>
          <p className="text-lg font-light mb-8 drop-shadow-md">
            Choose from our luxurious safaris and boats
          </p>
          <a
            href="#"
            className="inline-block text-white border border-white font-medium px-6 py-3 rounded-lg hover:bg-white hover:text-blue-700 transition transform hover:scale-105"
          >
            Explore Offers
          </a>
        </div>
      </header>

      {/* Safari List Section */}
      <SafariFeatured />

      {/* Statistics and Testimonials Section */}
      <section className="container mx-auto px-4 py-12 text-center">
          <h2 className="text-3xl font-bold mb-8">What People Say About Us</h2>
          {reviews.length > 0 && (
            <div className="bg-gray-100 py-12 rounded-lg shadow-lg">
              <div className="container mx-auto">
                <p className="text-lg italic text-gray-800 mb-6">
                  {reviews[currentReviewIndex]?.rating}
                </p>
                <div className="flex justify-center items-center space-x-4">
                  {reviews[currentReviewIndex]?.avatar && (
                    <img
                      src={`${process.env.REACT_APP_POCKETBASE_URL}/api/files/reviews/${reviews[currentReviewIndex].id}/${reviews[currentReviewIndex].avatar}`}
                      alt={reviews[currentReviewIndex]?.customer_name}
                      className="rounded-full w-16 h-16 shadow-md"
                    />
                  )}
                  <div>
                    <h4 className="text-lg font-bold text-gray-800">
                      {reviews[currentReviewIndex]?.customer_name}
                    </h4>
                    <p className="text-sm text-blue-600">Happy Customer</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>

      {/* Experience Section */}
      <section className="container mx-auto px-4 py-12">
        {/* First Row */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            {/* Image */}
            <div>
            <img
                src="https://www.iconredsea.com/wp-content/uploads/2024/10/IMG_3338.jpeg"
                alt="Luxury Yacht"
                className="rounded-lg shadow-lg"
            />
            </div>
            {/* Text */}
            <div>
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
                With 20+ Years of Experience
            </h2>
            <p className="text-gray-600 mb-6">
                With over 20 years of safari and diving experience, we invite you to
                explore the breathtaking waters of the Red Sea. Our experienced guides
                are ready to lead you on unforgettable diving adventures.
            </p>
            </div>
        </div>
        {/* Second Row */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center mt-12">
            {/* Text */}
            <div>
            <h2 className="text-4xl font-bold text-gray-800 mb-4">Make It the Best</h2>
            <p className="text-gray-600 mb-6">
                Unseen experiences await you. Join us for a range of exciting activities
                that will make your adventure unforgettable.
            </p>
            <ul className="list-disc pl-5 text-gray-600">
                <li className="mb-2">Diving</li>
                <li className="mb-2">Kite Surfing</li>
                <li className="mb-2">Relaxing on the deck</li>
            </ul>
            </div>
            {/* Image */}
            <div>
            <img
                src="https://www.iconredsea.com/wp-content/uploads/2024/10/pexels-richard-segal-732340-1645028-scaled.jpg"
                alt="Diving Experience"
                className="rounded-lg shadow-lg"
            />
            </div>
        </div>
      
      </section>


      {/* Gallery Section */}
      <section className="container mx-auto px-4 py-12">
      <h2 className="text-3xl font-bold text-center mb-8">Gallery</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {gallery.map((item, index) => (
          <div
            key={item.id}
            className="relative group cursor-pointer"
            onClick={() => openLightbox(index)}
          >
            <img
              src={`${process.env.REACT_APP_POCKETBASE_URL}/api/files/gallery/${item.id}/${item.image}?thumb=600x400`}
              alt={`Gallery Image ${item.id}`}
              className="rounded-lg shadow-lg object-cover w-full h-64"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition duration-300 rounded-lg">
              <p className="text-white font-semibold text-lg">View Fullscreen</p>
            </div>
          </div>
        ))}
      </div>
    </section>

    {/* Lightbox for fullscreen view */}
    {isOpen && images.length > 0 && (
       <Lightbox
       mainSrc={images[currentImageIndex] || ""}
       nextSrc={images[(currentImageIndex + 1) % images.length] || ""}
       prevSrc={images[(currentImageIndex - 1 + images.length) % images.length] || ""}
       onImageLoad={() => setIsLoading(false)}
       onCloseRequest={closeLightbox}
       onMovePrevRequest={movePrev}
       onMoveNextRequest={moveNext}
     />
     

    )}
    {isLoading && <div className="loading-indicator">Loading...</div>}
    </Base>
    </>
  );
};

export default Home;
