import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/public/Home";
import Auth from "./components/admin/Auth";
import Dashboard from "./components/admin/Dashboard";
import RoutesAdmin from "./components/admin/Routes";
import Tours from "./components/admin/Tours";
import ShipsAdmin from "./components/admin/Ships";
import ReviewsAdmin from "./components/admin/Reviews";
import GalleryAdmin from "./components/admin/Gallery";
import ShipCabins from "./components/admin/Rooms"; 
import Reservations from "./components/admin/Reservations";
import ReservationDetails from "./components/admin/ReservationDetals";
import ManagePrices from "./components/admin/ManagePrices";
import Safari from "./components/public/Safari";
import SafariDetails from "./components/public/SafariDetail";
import ScrollToTop from "./ui/Scrolltotop";
import Payment from "./components/public/Payment";
import Reservation from "./components/public/Reservation";
import Boats from "./components/public/Boats";
import BoatDetails from "./components/public/BoatDetails";
import CreateLocation from "./components/admin/CreateLocation";
import LocationsList from "./components/admin/LocationList";
import Locations from "./components/public/Locations";
import LocationDetails from "./components/public/LocationDetails";
import MyBookings from "./components/public/MyBookings";
import BankDetails from "./components/admin/BankDetails";
import CompanyRegister from "./components/public/company/Register";
import LoginCompany from "./components/public/company/Login";
import DashboardCompany from "./components/public/company/Dashboard";
import PaymentDone from "./components/public/PaymentDone";

import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

// Konfigurácia pre titulky a meta údaje
const routeMeta = {
  "/": { title: "Home - IconRedSea", description: "Discover the Beauty of Diving." },
  "/safari": { title: "Safari list - IconRedSea", description: "Discover the Beauty of Diving." },
  "/admin/login": { title: "Admin Login - IconRedSea", description: "Login to access the admin panel." },
  "/admin/auth": { title: "Admin Authentication - IconRedSea", description: "Authenticate to access admin panel." },
  "/admin/dashboard": { title: "Dashboard - IconRedSea", description: "Overview of your admin panel." },
  "/admin/routes": { title: "Routes Management - IconRedSea", description: "Manage available routes." },
  "/admin/tours": { title: "Tours Management - IconRedSea", description: "Manage available tours." },
  "/admin/ships": { title: "Ships Management - IconRedSea", description: "Manage available ships." },
  "/admin/reviews": { title: "Reviews Management - IconRedSea", description: "Manage customer reviews." },
  "/admin/gallery": { title: "Gallery Management - IconRedSea", description: "Manage gallery images." },
  "/admin/ships/:ship_id/cabins": { title: "Cabins Management - IconRedSea", description: "Manage ship cabins." },
  "/admin/tours/:tour_id/reservations": { title: "Reservations Management - IconRedSea", description: "Manage reservations for a tour." },
  "/admin/reservations/:id": { title: "Reservation Details - IconRedSea", description: "View and manage details of a specific reservation." },
  "/admin/tours/:tourId/manage-prices": { title: "Manage Cabin Prices - IconRedSea", description: "Edit and manage cabin prices for a tour." }, // Pridaná konfigurácia
};

// Komponent pre nastavovanie titulkov a meta údajov
const HelmetMeta = () => {
  const location = useLocation();
  const meta = routeMeta[location.pathname] || {
    title: "IconRedSea",
    description: "Welcome to IconRedSea.",
  };

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
    </Helmet>
  );
};

// Wrapper pre ShipCabins na získanie parametra ship_id z URL
const ShipCabinsWrapper = () => {
  const { ship_id } = useParams();
  return <ShipCabins ship_id={ship_id} />;
};

// Wrapper pre Reservations na získanie parametra tour_id z URL
const ReservationsWrapper = () => {
  const { tour_id } = useParams();
  return <Reservations tour_id={tour_id} />;
};

// Wrapper pre ManagePrices na získanie parametra tourId z URL
const ManagePricesWrapper = () => {
  const { tourId } = useParams();
  return <ManagePrices tourId={tourId} />;
};

function App() {


  useEffect(() => {
    const enforceNumericInput = (event) => {
      const target = event.target;
      if (target.tagName === "INPUT" && target.type === "number") {
        const value = target.value;

        // Povolenie len čísiel
        target.value = value.replace(/\D/g, ""); // Odstráni všetky nečíselné znaky
      }
    };

    const preventInvalidKeys = (event) => {
      const target = event.target;
      if (target.tagName === "INPUT" && target.type === "number") {
        if (["e", "E", "+", "-", ".", ","].includes(event.key)) {
          event.preventDefault(); // Zablokuje nepovolené klávesy
        }
      }
    };

    // Pridanie listenerov na celý dokument
    document.addEventListener("input", enforceNumericInput);
    document.addEventListener("keydown", preventInvalidKeys);

    return () => {
      // Odstránenie listenerov pri unmountovaní
      document.removeEventListener("input", enforceNumericInput);
      document.removeEventListener("keydown", preventInvalidKeys);
    };
  }, []);
  
  return (
    <Router>
      <div className="App">
        <ScrollToTop /> {/* Posúva stránku na vrch pri zmene trasy */}
        <HelmetMeta />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/my-bookings/:reservation_id?" element={<MyBookings />} />
          <Route path="/payment-done" element={<PaymentDone />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/locations/:id" element={<LocationDetails />} />
          <Route path="/boats" element={<Boats />} />
          <Route path="/boats/:id" element={<BoatDetails />} />
          <Route path="/safari" element={<Safari />} />
          <Route path="/payment/:reservationId" element={<Payment />} />
          <Route path="/safari/:id" element={<SafariDetails />} />
          <Route path="/admin/login" element={<Auth />} />
          <Route path="/admin/bank-details" element={<BankDetails />} />
          <Route path="/admin/auth" element={<Auth />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/locations/create" element={<CreateLocation />} />
          <Route path="/admin/locations" element={<LocationsList />} />
          <Route path="/admin/routes" element={<RoutesAdmin />} />
          <Route path="/admin/tours" element={<Tours />} />
          <Route path="/admin/ships" element={<ShipsAdmin />} />
          <Route path="/admin/reviews" element={<ReviewsAdmin />} />
          <Route path="/admin/gallery" element={<GalleryAdmin />} />
          <Route path="/admin/ships/:ship_id/cabins" element={<ShipCabinsWrapper />} />
          <Route path="/admin/tours/:tour_id/reservations" element={<ReservationsWrapper />} />
          <Route path="/admin/reservations/:id" element={<ReservationDetails />} />
          <Route path="/admin/tours/:tourId/manage-prices" element={<ManagePricesWrapper />} /> {/* Pridaný route */}
          <Route path="/reservation/:tourId/:cabinId" element={<Reservation />} />
          <Route path="/company/register" element={<CompanyRegister />} />
          <Route path="/company/login" element={<LoginCompany />} />
          <Route path="/company/dashboard" element={<DashboardCompany />} />
    
        </Routes>
      </div>
    </Router>
  );
}

export default App;
