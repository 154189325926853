import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import Base from "./Base";
import Modal from "./Modal"; // Jednoduchý Modal komponent

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const Reservations = ({ tour_id }) => {
  const [reservations, setReservations] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const navigate = useNavigate();

  // Overenie, či je používateľ prihlásený
  const checkAuth = () => {
    if (!pb.authStore.isValid) {
      navigate("/admin/auth"); // Ak nie je prihlásený, presmeruj na prihlasovaciu stránku
    }
  };

  // Fetch reservations for the tour
  const fetchReservations = async () => {
    try {
      const records = await pb.collection("reservations").getFullList({
        filter: `tour='${tour_id}'`,
        sort: "-updated",
      });
      setReservations(records);
    } catch (error) {
      console.error("Error fetching reservations:", error);
    }
  };

  // Update documents_done status
  const handleUpdateDocumentsDone = async (reservationId, status) => {
    try {
      await pb.collection("reservations").update(reservationId, {
        documents_done: status,
      });
      fetchReservations(); // Refresh the reservations list
    } catch (error) {
      console.error("Error updating documents status:", error);
    }
  };

  useEffect(() => {
    checkAuth();
    fetchReservations();
  }, [tour_id]);

  return (
    <Base>
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Reservations Management</h1>

        {reservations.length === 0 ? (
          <p>No reservations found for this tour.</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {reservations.map((reservation) => (
              <div
                key={reservation.id}
                className="border rounded-lg p-4 shadow-md relative"
              >
                <h3 className="text-lg font-semibold">
                  {reservation.first_name} {reservation.last_name}
                </h3>
                <p className="text-sm text-gray-600">Email: {reservation.email}</p>
                <p className="text-sm text-gray-600 mt-2">
                  Documents Status:{" "}
                  <span
                    className={`font-bold ${
                      reservation.documents_done ? "text-green-600" : "text-red-600"
                    }`}
                  >
                    {reservation.documents_done ? "Completed" : "Pending"}
                  </span>
                </p>

                <div className="mt-2">
                  {/* Tlačidlo na detaily rezervácie */}
                  <button
                    className="text-blue-500 underline"
                    onClick={() =>
                      navigate(`/admin/reservations/${reservation.id}`)
                    }
                  >
                    View Details
                  </button>

                  
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Document Modal */}
        {isDocumentModalOpen && selectedReservation && (
          <Modal onClose={() => setIsDocumentModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">
              Documents for {selectedReservation.first_name}{" "}
              {selectedReservation.last_name}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {selectedReservation.documents.map((doc, index) => (
                <img
                  key={index}
                  src={`${process.env.REACT_APP_POCKETBASE_URL}/api/files/reservations/${selectedReservation.id}/${doc}`}
                  alt={`Document ${index + 1}`}
                  className="w-full h-auto rounded-lg shadow-md"
                />
              ))}
            </div>
            <button
              className="bg-gray-300 px-4 py-2 rounded-lg mt-4"
              onClick={() => setIsDocumentModalOpen(false)}
            >
              Close
            </button>
          </Modal>
        )}
      </div>
    </Base>
  );
};

export default Reservations;
