import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Modal from './Modal'; // Jednoduchý Modal komponent
import Base from './Base';
import { useDropzone } from 'react-dropzone';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const GalleryAdmin = () => {
  const [gallery, setGallery] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]); // Pridanie useState pre uploadedFiles
  const navigate = useNavigate();

  // Overenie, či je používateľ prihlásený
  const checkAuth = () => {
    if (!pb.authStore.isValid) {
      navigate('/admin/auth'); // Ak nie je prihlásený, presmeruj na prihlasovaciu stránku
    }
  };

  // Fetch all gallery images
  const fetchGallery = async () => {
    try {
      const records = await pb.collection('gallery').getFullList({
        sort: '-updated', // Zoradenie podľa poslednej aktualizácie
      });
      setGallery(records);
    } catch (error) {
      console.error('Error fetching gallery:', error);
    }
  };

  // Funkcia na vytvorenie záznamu v galérii
const handleCreateGalleryItems = async () => {
  if (uploadedFiles.length === 0) {
    alert('Please upload at least one image.');
    return;
  }

  try {
    for (const file of uploadedFiles) {
      const formData = new FormData();
      formData.append('image', file);

      // Každý obrázok pošle samostatný request
      await pb.collection('gallery').create(formData);
    }

    setIsCreateModalOpen(false);
    setUploadedFiles([]); // Reset súborov
    fetchGallery(); // Obnoviť zoznam galérie
  } catch (error) {
    console.error('Error uploading gallery items:', error);
    alert('An error occurred while uploading the images.');
  }
};

  // Handle file upload using react-dropzone
  const onDrop = useCallback((acceptedFiles) => {
    setUploadedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]); // Pridanie súborov
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*', // Povoliť len obrázky
    multiple: true, // Povolenie viacerých súborov
  });

  // Funkcia na odstránenie súboru zo zoznamu
  const removeFile = (index) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((_, fileIndex) => fileIndex !== index)
    );
  };

  // Create a new gallery entry
  const handleCreateGalleryItem = async () => {
    if (uploadedFiles.length === 0) {
      alert('Please upload at least one image.');
      return;
    }

    try {
      const formData = new FormData();
      uploadedFiles.forEach((file) => {
        formData.append('images', file); // Ukladanie všetkých obrázkov
      });

      await pb.collection('gallery').create(formData);

      setIsCreateModalOpen(false);
      setUploadedFiles([]); // Reset súborov
      fetchGallery();
    } catch (error) {
      console.error('Error creating gallery item:', error);
    }
  };

  // Delete a gallery item
  const handleDeleteGalleryItem = async () => {
    if (!selectedImage) return;
    try {
      await pb.collection('gallery').delete(selectedImage.id);
      setIsDeleteModalOpen(false);
      setSelectedImage(null);
      fetchGallery();
    } catch (error) {
      console.error('Error deleting gallery item:', error);
    }
  };

  useEffect(() => {
    checkAuth();
    fetchGallery();
  }, []);

  return (
    <Base>
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Gallery Management</h1>

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-4"
          onClick={() => {
            setIsCreateModalOpen(true);
            setUploadedFiles([]); // Reset uploadedFiles pri otvorení modalu
          }}
        >
          Add New Image
        </button>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {gallery.map((item) => (
            <div
              key={item.id}
              className="relative border shadow-md rounded-lg overflow-hidden group hover:shadow-lg transition"
            >
              <img
                src={`${process.env.REACT_APP_POCKETBASE_URL}/api/files/gallery/${item.id}/${item.image}?thumb=300x300`}
                alt="Gallery Item"
                className="w-full h-64 object-cover group-hover:scale-105 transition-transform"
              />
              <button
                className="absolute top-2 right-2 bg-red-500 text-white px-3 py-1 text-sm rounded-lg shadow-md hover:bg-red-600 transition"
                onClick={() => {
                  setSelectedImage(item);
                  setIsDeleteModalOpen(true);
                }}
              >
                Delete
              </button>
            </div>
          ))}
        </div>

        {/* Create Gallery Modal */}
        {isCreateModalOpen && (
          <Modal onClose={() => setIsCreateModalOpen(false)}>
          <h2 className="text-xl font-bold mb-4">Add New Images</h2>
      
          {/* Drag and Drop Zone */}
          <div
            {...getRootProps()}
            className={`border-dashed border-2 p-4 rounded-lg text-center ${
              isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
            }`}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the images here...</p>
            ) : (
              <p>Drag & drop images here, or click to select files</p>
            )}
          </div>
      
          {/* Thumbnail Preview */}
          {uploadedFiles.length > 0 && (
            <div className="mt-4">
              {uploadedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center gap-4 p-2 border rounded-lg mb-2"
                >
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index}`}
                    className="w-16 h-16 object-cover rounded-lg"
                  />
                  <p className="flex-1 text-sm text-gray-700">{file.name}</p>
                  <button
                    onClick={() => removeFile(index)}
                    className="bg-red-500 text-white px-2 py-1 rounded-lg"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          )}
      
          <div className="flex justify-end space-x-2 mt-4">
            <button
              className="bg-gray-300 px-4 py-2 rounded-lg"
              onClick={() => setIsCreateModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-lg"
              onClick={handleCreateGalleryItems} // Aktualizovaná funkcia na postupné nahrávanie
            >
              Upload Images
            </button>
          </div>
        </Modal>
        )}

        {/* Delete Confirmation Modal */}
        {isDeleteModalOpen && (
          <Modal onClose={() => setIsDeleteModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this image?</p>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
                onClick={handleDeleteGalleryItem}
              >
                Delete
              </button>
            </div>
          </Modal>
        )}
      </div>
    </Base>
  );
};

export default GalleryAdmin;
