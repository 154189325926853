import PocketBase from 'pocketbase';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authStatus, setAuthStatus] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(pb.authStore.isValid);
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is already authenticated
    if (pb.authStore.isValid) {
      setAuthStatus('success');
      setUserData(pb.authStore.record);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const authData = await pb.collection('_superusers').authWithPassword(email, password);

      // Set auth state and user data
      setAuthStatus('success');
      setIsLoggedIn(true);
      setUserData(authData.record);

      console.log('Auth successful:', authData);
      console.log('Token:', pb.authStore.token);
      console.log('User ID:', pb.authStore.record.id);

      // Redirect to dashboard
      navigate('/admin/dashboard');
    } catch (error) {
      setAuthStatus('error');
      setIsLoggedIn(false);
      console.error('Error during authentication:', error);
    }
  };

  const handleLogout = () => {
    pb.authStore.clear();
    setAuthStatus('logged out');
    setIsLoggedIn(false);
    setUserData(null);
    console.log('User logged out');
  };

  return (
    <div className="max-w-md mx-auto p-8 bg-white shadow-lg rounded-lg mt-10">
      <h1 className="text-2xl font-bold text-center mb-6">Admin Login</h1>

      {!isLoggedIn ? (
        <>
          <form onSubmit={handleLogin} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-gray-700 font-medium mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-gray-700 font-medium mb-1">
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition"
            >
              Login
            </button>
          </form>
          {authStatus === 'error' && (
            <p className="mt-4 text-red-600 text-center">Error: Invalid email or password.</p>
          )}
        </>
      ) : (
        <>
          <div className="text-center">
            <p className="text-lg font-medium text-gray-800">Welcome, {userData?.email}!</p>
          </div>
          <button
            onClick={handleLogout}
            className="mt-4 w-full bg-gray-500 text-white py-2 rounded-lg hover:bg-gray-600 transition"
          >
            Logout
          </button>
        </>
      )}

      {authStatus === 'logged out' && (
        <p className="mt-4 text-gray-600 text-center">You have been logged out.</p>
      )}
    </div>
  );
};

export default Auth;
