import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import Modal from "./Modal"; // Komponent pre modálne okno
import Base from "./Base";
import { useDropzone } from "react-dropzone";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const ShipsAdmin = () => {
  const [ships, setShips] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedShip, setSelectedShip] = useState(null);
  const [newShip, setNewShip] = useState({
    name: "",
    description: "",
    size: 0,
    photos: [],
  });
  const navigate = useNavigate();

  const checkAuth = () => {
    if (!pb.authStore.isValid) {
      navigate("/admin/auth");
    }
  };

  const fetchShips = async () => {
    try {
      const records = await pb.collection("ships").getFullList({
        sort: "-updated",
      });
      setShips(records);
    } catch (error) {
      console.error("Error fetching ships:", error);
    }
  };

  const handleCreateShip = async () => {
    const { name, description, size, photos } = newShip;
    if (!name || size <= 0 || !description || photos.length === 0) {
      alert("Please fill in all required fields, including at least one photo.");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("size", size);
    photos.forEach((photo) => {
      formData.append("photos", photo);
    });

    try {
      await pb.collection("ships").create(formData);
      setIsCreateModalOpen(false);
      setNewShip({ name: "", description: "", size: 0, photos: [] });
      fetchShips();
    } catch (error) {
      console.error("Error creating ship:", error);
    }
  };

  const handleDeleteShip = async () => {
    if (!selectedShip) return;

    try {
      await pb.collection("ships").delete(selectedShip.id);
      setIsDeleteModalOpen(false);
      setSelectedShip(null);
      fetchShips();
    } catch (error) {
      console.error("Error deleting ship:", error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setNewShip({ ...newShip, photos: [...newShip.photos, ...acceptedFiles] });
    },
  });

  const removePhoto = (index) => {
    const updatedPhotos = newShip.photos.filter((_, i) => i !== index);
    setNewShip({ ...newShip, photos: updatedPhotos });
  };

  useEffect(() => {
    checkAuth();
    fetchShips();
  }, []);

  return (
    <Base>
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Ships Management</h1>

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-4"
          onClick={() => setIsCreateModalOpen(true)}
        >
          Create New Ship
        </button>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {ships.map((ship) => (
            <div
              key={ship.id}
              className="border rounded-lg p-4 shadow-md relative"
            >
              <h3 className="text-lg font-semibold">{ship.name}</h3>
              <p className="text-sm text-gray-600">{ship.description}</p>
              <p className="text-sm text-gray-600 mt-2">Size: {ship.size} m²</p>
              <button
                className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 text-xs rounded"
                onClick={() => {
                  setSelectedShip(ship);
                  setIsDeleteModalOpen(true);
                }}
              >
                Delete
              </button>
            </div>
          ))}
        </div>

        {isCreateModalOpen && (
          <Modal onClose={() => setIsCreateModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">Create New Ship</h2>
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Ship Name
            </label>
            <input
              type="text"
              placeholder="Enter ship name"
              value={newShip.name}
              onChange={(e) =>
                setNewShip({ ...newShip, name: e.target.value })
              }
              className="border p-2 rounded-lg w-full mb-4"
            />

            <label className="block mb-2 text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              placeholder="Enter description"
              value={newShip.description}
              onChange={(e) =>
                setNewShip({ ...newShip, description: e.target.value })
              }
              className="border p-2 rounded-lg w-full mb-4"
            />

            <label className="block mb-2 text-sm font-medium text-gray-700">
              Size
            </label>
            <input
              type="number"
              placeholder="Enter ship size"
              value={newShip.size || ""}
              onChange={(e) =>
                setNewShip({ ...newShip, size: Number(e.target.value) })
              }
              className="border p-2 rounded-lg w-full mb-4"
            />

            <label className="block mb-2 text-sm font-medium text-gray-700">
              Upload Photos
            </label>
            <div
              {...getRootProps()}
              className="p-4 rounded-lg mb-4 cursor-pointer border-2 border-dashed border-gray-500"
            >
              <input {...getInputProps()} />
              <p>
                Drag & drop files here, or click to select files.
              </p>
            </div>

            {newShip.photos.length > 0 && (
              <div className="mb-4">
                {newShip.photos.map((photo, index) => (
                  <div key={index} className="flex items-center gap-2 mb-2">
                    <img
                      src={URL.createObjectURL(photo)}
                      alt={`Preview ${index}`}
                      className="w-16 h-16 object-cover rounded-lg"
                    />
                    <p className="flex-1">{photo.name}</p>
                    <button
                      className="bg-red-500 text-white px-2 py-1 rounded-lg"
                      onClick={() => removePhoto(index)}
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            )}

            <div className="flex justify-end space-x-2">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg z-10"
                onClick={() => setIsCreateModalOpen(false)}
                role="button"
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg z-10"
                onClick={handleCreateShip}
                role="button"
              >
                Create
              </button>
            </div>
          </Modal>
        )}

        {isDeleteModalOpen && (
          <Modal onClose={() => setIsDeleteModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete ship: {selectedShip?.name}?</p>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
                onClick={handleDeleteShip}
              >
                Delete
              </button>
            </div>
          </Modal>
        )}
      </div>
    </Base>
  );
};

export default ShipsAdmin;
