import React, { useEffect, useState } from "react";
import PocketBase from "pocketbase";
import { useNavigate } from "react-router-dom";
import Base from "../Base";
import { useDropzone } from "react-dropzone";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const DashboardCompany = () => {
  const [reservations, setReservations] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const formatPaymentMethod = (method) => {
    switch (method) {
      case "bank_transfer":
        return "Bank Transfer";
      case "card":
        return "Credit/Debit Card";
      default:
        return "Unknown";
    }
  };

  const formatStatus = (status) => {
    switch (status) {
      case "pending":
        return "Pending";
      case "canceled":
        return "Canceled";
      case "accepted":
        return "Accepted";
      case "failed":
        return "Failed";
      case "paid":
        return "Paid";
      default:
        return "Unknown";
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const fetchReservationDetails = async (id) => {
    setLoading(true);
    try {
      const record = await pb.collection("reservations").getOne(id, {
        expand: "tour,cabin",
      });
      setSelectedReservation(record);
    } catch (err) {
      console.error("Error fetching reservation details:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDrop = async (acceptedFiles, reservationId) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append("documents", file);
    });

    try {
      await pb.collection("reservations").update(reservationId, formData);
      alert("Documents uploaded successfully!");
      fetchReservationDetails(reservationId); // Refresh reservation details
    } catch (err) {
      console.error("Error uploading documents:", err);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => handleDrop(files, selectedReservation.id),
  });

  const changePaymentMethod = async (method, reservationId) => {
    try {
      await pb.collection("reservations").update(reservationId, {
        payment_method: method,
      });
      fetchReservationDetails(reservationId); // Refresh reservation details
    } catch (err) {
      console.error("Error updating payment method:", err);
    }
  };

  useEffect(() => {
    const fetchReservations = async () => {
      setLoading(true);
      try {
        if (!pb.authStore.isValid) {
          navigate("/company/login");
          return;
        }

        const user = pb.authStore.model;

        if (!user || user.profile_type !== "company") {
          setError("403: You do not have permission to access this page.");
          return;
        }

        const result = await pb.collection("custom_reservations").getList(page, 10, {
          filter: `owner="${user.id}"`,
          sort: "-created",
        });

        setReservations(result.items);
        setTotalPages(result.totalPages);
      } catch (err) {
        console.error(err);
        setError("An error occurred while fetching reservations.");
      } finally {
        setLoading(false);
      }
    };

    fetchReservations();
  }, [page, navigate]);


  return (
    <Base>
      <div className="container mx-auto px-4 py-6">
        <h1 className="text-2xl font-bold mb-4">Travel Agency Dashboard</h1>

        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            {selectedReservation ? (
              <div className="p-6 bg-white shadow-md rounded-md">
                <h2 className="text-xl font-bold mb-4">
                  Reservation Details: {selectedReservation.id}
                </h2>
                <p>
                  <strong>Payment Method:</strong>{" "}
                  {formatPaymentMethod(selectedReservation.payment_method)}
                </p>
                <p>
                  <strong>Payment Status:</strong> {formatStatus(selectedReservation.payment_status)}
                </p>
                <p>
                  <strong>Status:</strong> {formatStatus(selectedReservation.status)}
                </p>
                <p>
                  <strong>Created:</strong> {formatDate(selectedReservation.created)}
                </p>
                <p>
                    <strong>Amount:</strong> {new Intl.NumberFormat('sk-SK', { style: 'currency', currency: 'EUR' }).format(selectedReservation.total_amount)}
                </p>
                <p>
                  <strong>Passengers:</strong>
                </p>
                <ul>
                  {selectedReservation.passengers?.map((passenger, index) => (
                    <li key={index}>
                      {passenger.first_name} {passenger.last_name} - {passenger.email}{" "}
                      {passenger.is_healthy ? "(Healthy)" : "(Not Healthy)"}
                    </li>
                  ))}
                </ul>
                <div className="mt-4">
                <h3 className="font-bold mb-2">Documents:</h3>
                {selectedReservation.documents?.length === 0 ? (
                <p>Documents are pending.</p>
                ) : selectedReservation.documents_done ? (
                <p>All documents are approved.</p>
                ) : (
                <p>Documents are under review.</p>
                )}
                {selectedReservation.documents?.length === 0 || selectedReservation.documents_done ? (
                <div
                    {...getRootProps()}
                    className="p-4 border border-dashed border-gray-400 rounded-md mt-2"
                >
                    <input {...getInputProps()} />
                    <p>Drag and drop files here, or click to select files</p>
                </div>
                ) : null}
                </div>

                {(selectedReservation.payment_status === "failed" || selectedReservation.payment_status === "pending") && (
                    <div className="mt-4">
                        <label htmlFor="payment-method" className="block font-bold mb-2">
                        Change Payment Method:
                        </label>
                        <select
                        id="payment-method"
                        onChange={(e) => changePaymentMethod(e.target.value, selectedReservation.id)}
                        defaultValue={selectedReservation.payment_method}
                        className="p-2 border border-gray-300 rounded-md w-full"
                        >
                        <option value="card">Credit/Debit Card</option>
                        <option value="bank_transfer">Bank Transfer</option>
                        </select>
                    </div>
                )}

                {(selectedReservation.payment_status === "failed" || selectedReservation.payment_status === "pending") && (
                    <div className="mt-4">
                        <a
                        href={`/payment/${selectedReservation.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-green-600 text-white px-4 py-2 rounded-md inline-block text-center"
                        >
                        Pay Now
                        </a>
                    </div>
                )}

                <button
                  onClick={() => setSelectedReservation(null)}
                  className="mt-4 bg-blue-600 text-white px-4 py-2 rounded-md"
                >
                  Back to List
                </button>
              </div>
            ) : (
              <div>
                {reservations.length === 0 ? (
                  <p>No reservations found.</p>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {reservations.map((reservation) => (
                      <div
                        key={reservation.id}
                        className="p-4 bg-white shadow-md rounded-md"
                      >
                        <p>
                          <strong>Reservation ID:</strong> {reservation.reservation}
                        </p>
                        <p>
                          <strong>Amount to Pay:</strong> {new Intl.NumberFormat('sk-SK', { style: 'currency', currency: 'EUR' }).format(reservation.amount_to_pay)}
                        </p>
                        <p>
                          <strong>Payment Method:</strong>{" "}
                          {formatPaymentMethod(reservation.payment_method)}
                        </p>
                        <p>
                          <strong>Created:</strong> {formatDate(reservation.created)}
                        </p>
                        <button
                          className="mt-4 w-full bg-blue-600 text-white px-4 py-2 rounded-md"
                          onClick={() => fetchReservationDetails(reservation.reservation)}
                        >
                          View Details
                        </button>
                      </div>
                    ))}
                  </div>                  
                )}

                {totalPages > 1 && (
                  <div className="flex justify-center mt-6">
                    <button
                      onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                      disabled={page === 1}
                      className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2 disabled:opacity-50"
                    >
                      Previous
                    </button>
                    <span className="px-4 py-2">{`Page ${page} of ${totalPages}`}</span>
                    <button
                      onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
                      disabled={page === totalPages}
                      className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md ml-2 disabled:opacity-50"
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Base>
  );
};

export default DashboardCompany;
