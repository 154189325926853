import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import Base from "../Base";

const pb = new PocketBase("http://127.0.0.1:8090");

const CompanyRegister = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
    company_name: "",
    company_number: "",
    vat_number: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    const data = {
      ...formData,
      emailVisibility: false,
      verified: false,
      profile_type: "company",
    };

    try {
      // Create user record
      await pb.collection("users").create(data);

      // Login the user
      await pb.collection("users").authWithPassword(formData.email, formData.password);

      // Redirect to company dashboard
      navigate("/company/dashboard");
    } catch (err) {
      setError(err.message || "An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Base>
     <div className="container mx-auto px-4 py-10">
  <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-md">
    <h1 className="text-2xl font-bold mb-6 text-center">Company Registration</h1>
    {error && <p className="text-red-500 mb-4">{error}</p>}
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2 font-medium">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="example@company.com"
            required
            className="p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-2 font-medium">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="At least 6 characters"
            required
            className="p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="passwordConfirm" className="block mb-2 font-medium">
            Confirm Password
          </label>
          <input
            type="password"
            id="passwordConfirm"
            name="passwordConfirm"
            value={formData.passwordConfirm}
            onChange={handleChange}
            placeholder="Re-enter your password"
            required
            className="p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="company_name" className="block mb-2 font-medium">
            Company Name
          </label>
          <input
            type="text"
            id="company_name"
            name="company_name"
            value={formData.company_name}
            onChange={handleChange}
            placeholder="e.g., Tech Solutions Ltd."
            required
            className="p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="company_number" className="block mb-2 font-medium">
            Company Number
          </label>
          <input
            type="text"
            id="company_number"
            name="company_number"
            value={formData.company_number}
            onChange={handleChange}
            placeholder="e.g., 47668890"
            required
            className="p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="vat_number" className="block mb-2 font-medium">
            VAT Number (optional)
          </label>
          <input
            type="text"
            id="vat_number"
            name="vat_number"
            value={formData.vat_number}
            onChange={handleChange}
            placeholder="e.g., GB123456789"
            className="p-2 border border-gray-300 rounded-md w-full"
          />
        </div>
      </div>
      <button
        type="submit"
        disabled={loading}
        className={`p-2 w-full bg-blue-600 text-white rounded-md ${
          loading ? "opacity-50 cursor-not-allowed" : ""
        }`}
      >
        {loading ? "Registering..." : "Register"}
      </button>
    </form>
    <p className="text-center mt-4 text-gray-600">
      Already have an account?{" "}
      <a href="/company/login" className="text-blue-600 underline">
        Login here
      </a>
    </p>
  </div>
</div>

    </Base>
  );
};

export default CompanyRegister;
