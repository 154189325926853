import React, { useState, useEffect } from "react";
import PocketBase from "pocketbase";
import { useDropzone } from "react-dropzone";
import Base from "./Base";
import { useNavigate } from "react-router-dom";


const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const CreateLocation = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);

  const checkAuth = () => {
    if (!pb.authStore.isValid) {
      navigate("/admin/auth");
    }
  };


  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setPhotos([...photos, ...acceptedFiles]);
    },
  });

  const removePhoto = (index) => {
    const updatedPhotos = photos.filter((_, i) => i !== index);
    setPhotos(updatedPhotos);
  };

  const handleSubmit = async () => {
    if (!title || !description || photos.length === 0) {
      alert("Please fill in all fields and upload at least one photo.");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    photos.forEach((photo) => formData.append("photos", photo));

    try {
      await pb.collection("locations").create(formData);
      setTitle("");
      setDescription("");
      setPhotos([]);
      navigate('/admin/locations');
    } catch (error) {
      console.error("Error creating location:", error);
      alert("Failed to create location. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  });

  return (
    <Base>
      <div className="container mx-auto px-4 py-10">
        <h1 className="text-3xl font-bold text-blue-600 text-center mb-6">
          Create New Location
        </h1>
        <div className="bg-white shadow-md rounded-lg p-6">
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Title
          </label>
          <input
            type="text"
            placeholder="Enter location title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="border p-2 rounded-lg w-full mb-4"
          />

          <label className="block mb-2 text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            placeholder="Enter location description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="border p-2 rounded-lg w-full mb-4"
          />

          <label className="block mb-2 text-sm font-medium text-gray-700">
            Upload Photos
          </label>
          <div
            {...getRootProps()}
            className="p-4 rounded-lg mb-4 cursor-pointer border-2 border-dashed border-gray-500"
          >
            <input {...getInputProps()} />
            <p>
              Drag & drop files here, or click to select files.
            </p>
          </div>

          {photos.length > 0 && (
            <div className="mb-4">
              {photos.map((photo, index) => (
                <div key={index} className="flex items-center gap-2 mb-2">
                  <img
                    src={URL.createObjectURL(photo)}
                    alt={`Preview ${index}`}
                    className="w-16 h-16 object-cover rounded-lg"
                  />
                  <p className="flex-1">{photo.name}</p>
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded-lg"
                    onClick={() => removePhoto(index)}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          )}

          <button
            onClick={handleSubmit}
            className={`w-full bg-blue-600 text-white py-2 px-4 rounded ${
              loading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
            }`}
            disabled={loading}
          >
            {loading ? "Creating..." : "Create Location"}
          </button>
        </div>
      </div>
    </Base>
  );
};

export default CreateLocation;
