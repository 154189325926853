import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import Base from "./Base";
import Modal from "./Modal";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const BankDetails = () => {
  const [bankDetails, setBankDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    iban: "",
    swift: "",
    account_name: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!pb.authStore.isValid) {
      navigate("/admin/auth");
    } else {
      fetchBankDetails();
    }
  }, []);

  const fetchBankDetails = async () => {
    try {
      const records = await pb.collection("bank_details").getFullList();
      setBankDetails(records.length > 0 ? records[0] : null); // Max 1 bankový účet
    } catch (error) {
      console.error("Error fetching bank details:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSave = async () => {
    try {
      if (bankDetails) {
        await pb.collection("bank_details").update(bankDetails.id, formValues);
      } else {
        await pb.collection("bank_details").create(formValues);
      }
      setIsModalOpen(false);
      fetchBankDetails();
    } catch (error) {
      console.error("Error saving bank details:", error);
    }
  };

  const handleEdit = () => {
    setFormValues({
      iban: bankDetails?.iban || "",
      swift: bankDetails?.swift || "",
      account_name: bankDetails?.account_name || "",
    });
    setIsModalOpen(true);
  };

  return (
    <Base>
      <div className="container mx-auto px-4 py-6">
        <h1 className="text-2xl font-bold mb-4">Bank Details</h1>
        {bankDetails ? (
          <div className="bg-white p-6 rounded-lg shadow-md">
            <p>
              <strong>IBAN:</strong> {bankDetails.iban}
            </p>
            <p>
              <strong>SWIFT:</strong> {bankDetails.swift}
            </p>
            <p>
              <strong>Account Name:</strong> {bankDetails.account_name}
            </p>
            <button
              onClick={handleEdit}
              className="mt-4 bg-blue-600 text-white px-4 py-2 rounded-md"
            >
              Edit Bank Details
            </button>
          </div>
        ) : (
          <div>
            <p>No bank details available.</p>
            <button
              onClick={() => setIsModalOpen(true)}
              className="mt-4 bg-green-600 text-white px-4 py-2 rounded-md"
            >
              Add Bank Details
            </button>
          </div>
        )}
      </div>

      {isModalOpen && (
        <Modal onClose={() => setIsModalOpen(false)}>
          <h2 className="text-xl font-bold mb-4">
            {bankDetails ? "Edit Bank Details" : "Add Bank Details"}
          </h2>
          <form className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                IBAN
              </label>
              <input
                type="text"
                name="iban"
                value={formValues.iban}
                onChange={handleInputChange}
                className="p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                SWIFT
              </label>
              <input
                type="text"
                name="swift"
                value={formValues.swift}
                onChange={handleInputChange}
                className="p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Account Name
              </label>
              <input
                type="text"
                name="account_name"
                value={formValues.account_name}
                onChange={handleInputChange}
                className="p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded-md"
              >
                Cancel
              </button>
              <button
                type="button"
                role="button"
                onClick={handleSave}
                className="bg-blue-600 text-white px-4 py-2 rounded-md z-10" 
              >
                Save
              </button>
            </div>
          </form>
        </Modal>
      )}
    </Base>
  );
};

export default BankDetails;
