import React from "react";
import Base from "./Base";
import { useNavigate } from "react-router-dom";

const PaymentDone = () => {
  const navigate = useNavigate();

  return (
    <Base>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 text-gray-800">
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg text-center">
          <h1 className="text-3xl font-bold text-blue-600 mb-4">
            Thank You for Your Order!
          </h1>
          <p className="text-gray-600 mb-6">
            We appreciate your business and look forward to serving you. If you
            have any questions, feel free to contact us at{" "}
            <a
              href="mailto:info@iconredsea.com"
              className="text-blue-600 underline"
            >
              info@iconredsea.com
            </a>
            .
          </p>
          <button
            onClick={() => navigate("/")}
            className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700"
          >
            Back to Home
          </button>
        </div>
      </div>
    </Base>
  );
};

export default PaymentDone;
