import React, { useEffect, useState } from "react";
import PocketBase from "pocketbase";
import { useNavigate } from "react-router-dom";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
pb.autoCancellation(false);

const AvailableCabins = ({ tourId }) => {
  const [cabins, setCabins] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCabins = async () => {
      try {
        const tour = await pb.collection("tours").getFirstListItem(`id = "${tourId}"`, {
          expand: "ship",
        });
        const shipId = tour.expand?.ship?.id;

        if (!shipId) {
          console.error("Ship not found for the tour.");
          setLoading(false);
          return;
        }

        const fetchedCabins = await pb.collection("ship_cabins").getFullList({
          filter: `ship = "${shipId}"`,
          expand: "cabin",
        });

        const reservations = await pb.collection("reservations").getFullList({
          filter: `tour = "${tourId}" && (status = "pending" || status = "accepted")`,
        });

        const cabinsWithDetails = await Promise.all(
          fetchedCabins.map(async (cabin) => {
            const totalRooms = cabin.count || 0;

            const reservedRooms = reservations.filter(
              (res) => res.cabin === cabin.id
            ).length;

            const availableRooms = totalRooms - reservedRooms;

            // Fetch price for the cabin
            let price;
            try {
              const priceData = await pb.collection("cabins_prices").getFirstListItem(
                `tour = "${tourId}" && cabin = "${cabin.id}"`
              );
              price = priceData.price || "Price not available";
            } catch {
              price = "Price not available";
            }

            return {
              ...cabin,
              availableRooms,
              price,
              name: cabin.name || cabin.expand?.cabin?.name || "Unknown Cabin",
              description: cabin.description || cabin.expand?.cabin?.description || "No description available",
            };
          })
        );

        const availableCabins = cabinsWithDetails.filter(
          (cabin) => cabin.availableRooms > 0
        );

        setCabins(availableCabins);
      } catch (error) {
        console.error("Error fetching cabins:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCabins();
  }, [tourId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section className="container mx-auto px-4 py-12">
      <h2 className="text-2xl font-bold text-gray-800 mt-10 mb-4">
        Available Rooms
      </h2>
      {cabins.length === 0 ? (
        <p className="text-gray-600">No available rooms at the moment.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {cabins.map((cabin) => (
            <div
              key={cabin.id}
              className="bg-white shadow-lg rounded-lg flex flex-col"
            >
              <div className="relative">
                <img
                  src={
                    cabin.photos?.[0]
                      ? `${process.env.REACT_APP_POCKETBASE_URL}/api/files/ship_cabins/${cabin.id}/${cabin.photos[0]}`
                      : "https://via.placeholder.com/400x250"
                  }
                  alt={cabin.name}
                  className="w-full h-40 object-cover rounded-t-lg"
                />
              </div>
              <div className="p-4 flex-grow flex flex-col">
                <h3 className="text-lg font-semibold text-gray-800">
                  {cabin.name}
                </h3>
                <p className="text-gray-600 text-sm mt-1 flex-grow">
                  {cabin.description}
                </p>
                <p className="text-gray-800 mt-4">
                  <strong>Rooms:</strong> {cabin.availableRooms} available
                </p>
                <p className="text-gray-800 mt-1">
                  <strong>Price:</strong> {cabin.price}€
                </p>
              </div>
              <div className="p-4 pt-0">
                <button
                  onClick={() =>
                    navigate(`/reservation/${tourId}/${cabin.id}`)
                  }
                  className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 w-full"
                  disabled={!cabin.id}
                >
                  Book Now
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default AvailableCabins;
