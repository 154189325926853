import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import Base from "./Base";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const Locations = () => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getImageUrl = (collectionId, recordId, fileName) => {
    return `${process.env.REACT_APP_POCKETBASE_URL}/api/files/${collectionId}/${recordId}/${fileName}`;
  };

  const fetchLocations = async () => {
    try {
      const records = await pb.collection("locations").getFullList({
        sort: "-created",
      });
      const enrichedLocations = records.map((location) => ({
        ...location,
        previewImage: location.photos?.[0]
          ? getImageUrl(location.collectionId, location.id, location.photos[0])
          : "https://via.placeholder.com/400", // Placeholder image if no photos are available
      }));
      setLocations(enrichedLocations);
    } catch (error) {
      console.error("Error fetching locations:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  if (loading) {
    return (
      <Base>
        <div className="text-center py-10">Loading...</div>
      </Base>
    );
  }

  return (
    <Base>
      <div className="container mx-auto px-4 py-10">
        <h1 className="text-3xl font-bold text-gray-800 text-center mb-8">
          Our Locations
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {locations.map((location) => (
            <div
              key={location.id}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <img
                src={location.previewImage}
                alt={location.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h2 className="text-xl font-bold text-gray-800">
                  {location.title}
                </h2>
                <p className="text-gray-600 text-sm mt-2">
                  {location.description.length > 100
                    ? `${location.description.slice(0, 100)}...`
                    : location.description}
                </p>
                <button
                  onClick={() => navigate(`/locations/${location.id}`)}
                  className="mt-4 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 w-full"
                >
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Base>
  );
};

export default Locations;
