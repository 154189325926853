import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Base from "./Base";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const BoatDetails = () => {
  const { id } = useParams();
  const [boat, setBoat] = useState(null);
  const [cabins, setCabins] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [lightboxKey, setLightboxKey] = useState(0);

  const getImageUrl = (collectionId, recordId, fileName) => {
    return `${process.env.REACT_APP_POCKETBASE_URL}/api/files/${collectionId}/${recordId}/${fileName}`;
  };

  const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  const fetchCabins = async () => {
    try {
      const cabinsData = await pb.collection("ship_cabins").getFullList({
        filter: `ship="${id}"`,
        sort: "-updated",
      });
      const enrichedCabins = cabinsData.map((cabin) => ({
        ...cabin,
        photos: cabin.photos.map((photo) =>
          getImageUrl(cabin.collectionId, cabin.id, photo)
        ),
      }));
      setCabins(enrichedCabins);

      // Preload all images for cabins
      const cabinImages = enrichedCabins.flatMap((cabin) => cabin.photos);
      setImages((prevImages) => [...prevImages, ...cabinImages]);
      preloadImages(cabinImages);
    } catch (error) {
      console.error("Error fetching cabins:", error);
    }
  };

  useEffect(() => {
    const fetchBoatDetails = async () => {
      try {
        const boatData = await pb.collection("ships").getOne(id);
        const boatImages = boatData.photos.map((photo) =>
          getImageUrl(boatData.collectionId, boatData.id, photo)
        );
        setImages(boatImages);
        preloadImages(boatImages);
        setBoat(boatData);
        await fetchCabins();
      } catch (error) {
        console.error("Error fetching boat details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBoatDetails();
  }, [id]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setLightboxKey((prevKey) => prevKey + 1), 0);
    }
  }, [isOpen]);

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const moveNext = () => {
    if (images.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  const movePrev = () => {
    if (images.length > 0) {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
    }
  };

  if (loading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  if (!boat) {
    return <div className="text-center py-10">Boat not found</div>;
  }

  return (
    <Base>
      <div className="container mx-auto px-4 py-10">
        <h1 className="text-3xl font-bold text-blue-600 text-center mb-6">
          {boat.name}
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            {boat.photos?.map((photo, index) => (
              <img
                key={index}
                src={getImageUrl(boat.collectionId, boat.id, photo)}
                alt={`Boat Image ${index + 1}`}
                className="w-full h-64 object-cover mb-4 rounded"
                onClick={() => openLightbox(index)}
              />
            ))}
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <p className="text-gray-800">
              <strong>Description:</strong> {boat.description}
            </p>
            <p className="text-gray-800 mt-2">
              <strong>Size:</strong> {boat.size} m²
            </p>
          </div>
        </div>

        <h2 className="text-2xl font-bold text-gray-800 mt-10 mb-4">
          Available Cabins
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {cabins.map((cabin) => (
            <div key={cabin.id} className="bg-white shadow-lg rounded-lg">
              <div className="relative">
                <img
                  src={cabin.photos[0]}
                  alt={cabin.name}
                  className="w-full h-40 object-cover rounded-t-lg cursor-pointer"
                  onClick={() => openLightbox(images.indexOf(cabin.photos[0]))}
                />
                <button
                  className="absolute top-2 right-2 bg-blue-600 text-white text-xs py-1 px-2 rounded"
                  onClick={() => openLightbox(images.indexOf(cabin.photos[0]))}
                >
                  View Photos
                </button>
              </div>
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800">
                  {cabin.name}
                </h3>
                <p className="text-gray-600 text-sm mt-1">{cabin.description}</p>
                <p className="text-gray-800 mt-2">
                  <strong>Beds:</strong> {cabin.beds}
                </p>
                <p className="text-gray-800">
                  <strong>Available:</strong> {cabin.count}
                </p>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={() => navigate("/boats")}
          className="mt-6 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
        >
          Back to Ships
        </button>
      </div>

      {/* Lightbox for fullscreen view */}
      {isOpen && images.length > 0 && (
        <Lightbox
          key={lightboxKey}
          mainSrc={images[currentImageIndex] || ""}
          nextSrc={images[(currentImageIndex + 1) % images.length] || ""}
          prevSrc={images[(currentImageIndex - 1 + images.length) % images.length] || ""}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={movePrev}
          onMoveNextRequest={moveNext}
        />
      )}
    </Base>
  );
};

export default BoatDetails;
