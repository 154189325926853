import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import Base from "./Base";
import Modal from "./Modal"; // Predpokladáme jednoduchý komponent Modal

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const LocationsList = () => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const navigate = useNavigate();

  const checkAuth = () => {
    if (!pb.authStore.isValid) {
      navigate("/admin/auth");
    }
  };

  const getImageUrl = (collectionId, recordId, fileName) => {
    return `${process.env.REACT_APP_POCKETBASE_URL}/api/files/${collectionId}/${recordId}/${fileName}`;
  };

  const fetchLocations = async () => {
    try {
      const records = await pb.collection("locations").getFullList({
        sort: "-created",
      });
      const enrichedLocations = records.map((location) => ({
        ...location,
        previewImage: location.photos?.[0]
          ? getImageUrl(location.collectionId, location.id, location.photos[0])
          : "https://via.placeholder.com/400", // Placeholder image if no photos are available
      }));
      setLocations(enrichedLocations);
    } catch (error) {
      console.error("Error fetching locations:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteLocation = async () => {
    if (!selectedLocation) return;

    try {
      await pb.collection("locations").delete(selectedLocation.id);
      setLocations((prev) =>
        prev.filter((location) => location.id !== selectedLocation.id)
      );
      setIsDeleteModalOpen(false);
      setSelectedLocation(null);
    } catch (error) {
      console.error("Error deleting location:", error);
      alert("Failed to delete the location.");
    }
  };

  const handleEditLocation = (location) => {
    setSelectedLocation(location);
    setEditTitle(location.title);
    setEditDescription(location.description);
    setIsEditModalOpen(true);
  };

  const handleSaveChanges = async () => {
    if (!editTitle || !editDescription) {
      alert("Please fill in all fields.");
      return;
    }

    try {
      await pb.collection("locations").update(selectedLocation.id, {
        title: editTitle,
        description: editDescription,
      });
      setLocations((prev) =>
        prev.map((loc) =>
          loc.id === selectedLocation.id
            ? { ...loc, title: editTitle, description: editDescription }
            : loc
        )
      );
      setIsEditModalOpen(false);
    } catch (error) {
      console.error("Error updating location:", error);
      alert("Failed to update the location.");
    }
  };

  useEffect(() => {
    checkAuth();
    fetchLocations();
  }, []);

  if (loading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  return (
    <Base>
      <div className="container mx-auto px-4 py-10">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-gray-800">Locations</h1>
          <button
            onClick={() => navigate("/admin/locations/create")}
            className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 z-10"
            role="button"
          >
            Create New Location
          </button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {locations.map((location) => (
            <div
              key={location.id}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <img
                src={location.previewImage}
                alt={location.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h2 className="text-xl font-bold text-gray-800">{location.title}</h2>
                <p className="text-gray-600 text-sm mt-2">
                  {location.description.length > 100
                    ? `${location.description.slice(0, 100)}...`
                    : location.description}
                </p>
                <div className="flex justify-end space-x-2 mt-4">
                  <button
                    className="text-blue-600"
                    onClick={() => handleEditLocation(location)}
                  >
                    Edit
                  </button>
                  <button
                    className="text-red-600"
                    onClick={() => {
                      setSelectedLocation(location);
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Edit Modal */}
      {isEditModalOpen && (
        <Modal onClose={() => setIsEditModalOpen(false)}>
          <h2 className="text-xl font-bold mb-4">Edit Location</h2>
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Title
          </label>
          <input
            type="text"
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
            className="border p-2 rounded-lg w-full mb-4"
          />
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            value={editDescription}
            onChange={(e) => setEditDescription(e.target.value)}
            className="border p-2 rounded-lg w-full mb-4"
          />
          <div className="flex justify-end space-x-2">
            <button
              className="bg-gray-300 px-4 py-2 rounded-lg"
              onClick={() => setIsEditModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-lg z-10"
              onClick={handleSaveChanges}
              role="button"
            >
              Save
            </button>
          </div>
        </Modal>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <Modal onClose={() => setIsDeleteModalOpen(false)}>
          <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
          <p>
            Are you sure you want to delete location:{" "}
            <strong>{selectedLocation?.title}</strong>?
          </p>
          <div className="flex justify-end space-x-2 mt-4">
            <button
              className="bg-gray-300 px-4 py-2 rounded-lg"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded-lg"
              onClick={handleDeleteLocation}
            >
              Delete
            </button>
          </div>
        </Modal>
      )}
    </Base>
  );
};

export default LocationsList;
