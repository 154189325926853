const Modal = ({ children, onClose }) => {
  const handleOutsideClick = (e) => {
    // Ak je klik mimo obsahu modálneho okna, zavolá sa onClose
    if (e.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 modal-overlay"
      onClick={handleOutsideClick} // Nastavenie na zatvorenie pri kliknutí mimo
    >
      <div
        className="bg-white p-6 rounded-lg shadow-lg relative w-full max-w-md sm:max-w-lg overflow-y-auto"
        style={{ maxHeight: "90vh" }} // Nastavenie maximálnej výšky a overflow
        onClick={(e) => e.stopPropagation()} // Zastavenie kliknutia na samotné modálne okno
      >
        {children}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default Modal;
