import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PocketBase from "pocketbase";
import Base from "./Base";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const LocationDetails = () => {
  const { id } = useParams();
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);

  const getImageUrl = (collectionId, recordId, fileName) => {
    return `${process.env.REACT_APP_POCKETBASE_URL}/api/files/${collectionId}/${recordId}/${fileName}`;
  };

  const fetchLocationDetails = async () => {
    try {
      const record = await pb.collection("locations").getOne(id);
      const imageUrls = record.photos.map((photo) =>
        getImageUrl(record.collectionId, record.id, photo)
      );
      setImages(imageUrls);
      setLocation(record);
    } catch (error) {
      console.error("Error fetching location details:", error);
    } finally {
      setLoading(false);
    }
  };

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const moveNext = () => {
    if (images.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  const movePrev = () => {
    if (images.length > 0) {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
    }
  };

  useEffect(() => {
    fetchLocationDetails();
  }, [id]);

  if (loading) {
    return (
      <Base>
        <div className="text-center py-10">Loading...</div>
      </Base>
    );
  }

  if (!location) {
    return (
      <Base>
        <div className="text-center py-10">Location not found</div>
      </Base>
    );
  }

  return (
    <Base>
      <div className="container mx-auto px-4 py-10">
        <h1 className="text-3xl font-bold text-gray-800 text-center mb-8">
          {location.title}
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            {location.photos?.map((photo, index) => (
              <img
                key={index}
                src={getImageUrl(location.collectionId, location.id, photo)}
                alt={`Location Image ${index + 1}`}
                className="w-full h-64 object-cover mb-4 rounded cursor-pointer"
                onClick={() => openLightbox(index)}
              />
            ))}
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <p className="text-gray-800 mb-4">{location.description}</p>
          </div>
        </div>
        <button
          onClick={() => window.history.back()}
          className="mt-6 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
        >
          Back to Locations
        </button>
      </div>

      {/* Lightbox for fullscreen view */}
      {isOpen && images.length > 0 && (
        <Lightbox
          mainSrc={images[currentImageIndex] || ""}
          nextSrc={images[(currentImageIndex + 1) % images.length] || ""}
          prevSrc={images[(currentImageIndex - 1 + images.length) % images.length] || ""}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={movePrev}
          onMoveNextRequest={moveNext}
        />
      )}
    </Base>
  );
};

export default LocationDetails;
