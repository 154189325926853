import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import PocketBase from "pocketbase";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Base from "./Base";
import AvailableCabins from "./AvaliableCabins";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const SafariDetails = () => {
  const { id } = useParams();
  const [tour, setTour] = useState(null);
  const navigate = useNavigate(); 
  const [cabins, setCabins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [lightboxKey, setLightboxKey] = useState(0); 

  const getImageUrl = (collectionId, recordId, fileName) => {
    return `${process.env.REACT_APP_POCKETBASE_URL}/api/files/${collectionId}/${recordId}/${fileName}`;
  };

  const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  const fetchCabins = async () => {
    try {
      const cabinsData = await pb.collection("cabins_prices").getFullList({
        filter: `tour="${id}"`,
        expand: "cabin",
      });
      const enrichedCabins = cabinsData.map((cabin) => ({
        ...cabin,
        photos: cabin.expand.cabin.photos.map((photo) =>
          getImageUrl(cabin.expand.cabin.collectionId, cabin.expand.cabin.id, photo)
        ),
      }));
      setCabins(enrichedCabins);

      // Extract all image URLs and preload them
      const allImages = enrichedCabins.flatMap((cabin) => cabin.photos);
      setImages(allImages); // Update the images array
      preloadImages(allImages); // Preload the images
    } catch (error) {
      console.error("Error fetching cabins:", error);
    }
  };

  useEffect(() => {
    const fetchTourDetails = async () => {
      try {
        const tourData = await pb.collection("tours").getOne(id, {
          expand: "ship,route",
        });
        setTour(tourData);
        await fetchCabins();
      } catch (error) {
        console.error("Error fetching tour details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTourDetails();
  }, [id]);

  useEffect(() => {
    if (isOpen) {
      // Force Lightbox to re-render
      setTimeout(() => setLightboxKey((prevKey) => prevKey + 1), 0);
    }
  }, [isOpen]);

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const moveNext = () => {
    if (images.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  const movePrev = () => {
    if (images.length > 0) {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
    }
  };

  if (loading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  if (!tour) {
    return <div className="text-center py-10">Safari not found</div>;
  }

  return (
    <Base>
      <div className="container mx-auto px-4 py-10">
        <h1 className="text-3xl font-bold text-blue-600 text-center mb-6">
          {tour.name}
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div>
            {tour.photos?.map((photo, index) => (
              <img
                key={index}
                src={getImageUrl(tour.collectionId, tour.id, photo)}
                alt={`Tour Image ${index + 1}`}
                className="w-full h-64 object-cover mb-4 rounded"
                onClick={() => openLightbox(index)}
              />
            ))}
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <p className="text-gray-800">
              <strong>Description:</strong> {tour.description}
            </p>
            <p className="text-gray-800 mt-2">
              <strong>Max People:</strong> {tour.max_people}
            </p>
            <p className="text-gray-800 mt-2">
              <strong>Duration:</strong> {tour.duration} days
            </p>
            <p className="text-gray-800 mt-2">
              <strong>Route:</strong> {tour.expand?.route?.name || "Not specified"}
            </p>
          </div>
        </div>
        
        <AvailableCabins tourId={id} />
     
        <br />
        <button
        onClick={() => navigate("/safari")}
        className="mt-6 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
        >
          Back to Safari
        </button>
      </div>

      {/* Lightbox for fullscreen view */}
      {isOpen && images.length > 0 && (
        <Lightbox
          key={lightboxKey} // Force Lightbox re-render on key change
          mainSrc={images[currentImageIndex] || ""}
          nextSrc={images[(currentImageIndex + 1) % images.length] || ""}
          prevSrc={images[(currentImageIndex - 1 + images.length) % images.length] || ""}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={movePrev}
          onMoveNextRequest={moveNext}
        />
      )}
    </Base>
  );
};

export default SafariDetails;
