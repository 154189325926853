import React, { useEffect, useState } from "react";
import PocketBase from "pocketbase";
import { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import Base from "./Base";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const MyBookings = () => {
  const [email, setEmail] = useState("");
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const navigate = useNavigate();
  const { reservation_id } = useParams(); // Získanie reservation_id z URL
  const [paymentMethod, setPaymentMethod] = useState("card");

  // Fetch bookings by reservation_id or email
  const fetchBookings = async (reservationId) => {
    try {
      let resultList;
      if (reservationId) {
        // Fetch specific booking by ID
        const booking = await pb.collection("reservations").getOne(reservationId, {
          expand: "tour",
        });
        resultList = { items: [booking] };
      } else {
        // Fetch bookings by email
        resultList = await pb.collection("reservations").getList(1, 10, {
          filter: `email="${email}"`,
          sort: "-created",
          expand: "tour",
        });
      }
      setBookings(resultList.items);
      setHasSearched(true);
    } catch (error) {
      console.error("Error fetching bookings:", error);
      setHasSearched(true); // Set state to true even on error
    }
  };

  // Automatically fetch bookings if reservation_id is provided
  useEffect(() => {
    if (reservation_id) {
      fetchBookings(reservation_id);
    }
  }, [reservation_id]);

  const onDrop = async (acceptedFiles, bookingId) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append("documents", file);
    });

    try {
      await pb.collection("reservations").update(bookingId, formData);
      alert("Documents were successfully uploaded.");
      fetchBookings(reservation_id || null); // Refresh bookings
    } catch (error) {
      console.error("Error uploading documents:", error);
      alert("An error occurred while uploading documents.");
    }
  };

  const handlePayment = (bookingId) => {
    navigate(`/payment/${bookingId}`);
  };

  const BookingDetails = ({ booking }) => {
    const {
      total_amount,
      payment_status,
      payment_method,
      status,
      passengers,
      documents,
      tour,
      documents_done,
      id,
    } = booking;

    const parsedPassengers =
      typeof passengers === "string" ? JSON.parse(passengers) : passengers;

    const formattedPaymentMethod =
      payment_method === "bank_transfer" ? "Bank Transfer" : "Card";

    const formattedPaymentStatus = {
      pending: "Pending",
      failed: "Failed",
      paid: "Paid",
    }[payment_status] || "Unknown";

    const formattedStatus = status === "accepted" ? "Everything is ready" : null;

    const refreshBookingState = async () => {
      try {
        fetchBookings(reservation_id || null); // Refresh state
      } catch (error) {
        console.error("Error refreshing booking:", error);
      }
    };

    return (
      <div className="p-4 bg-gray-100 rounded-md shadow-md mt-4">
        <p>
          <strong>Trip Name:</strong> {booking.expand?.tour?.name || "Unknown Tour"}
        </p>
        <p>
          <strong>Payment Status:</strong> {formattedPaymentStatus}
        </p>
        <p>
          <strong>Total Amount:</strong> {total_amount} €
        </p>
        <p>
          <strong>Payment Method:</strong> {formattedPaymentMethod}
        </p>
        {formattedStatus && (
          <p>
            <strong>Status:</strong> {formattedStatus}
          </p>
        )}
        {documents_done && (
          <p>
            <strong>Documents:</strong> Approved
          </p>
        )}
        {!documents_done && documents && documents.length > 0 && (
          <p>
            <strong>Documents:</strong> Documents are under review
          </p>
        )}
        <p>
          <strong>Passengers:</strong>
        </p>
        <ul>
          {parsedPassengers.map((passenger, index) => (
            <li key={index}>
              {passenger.first_name} {passenger.last_name} ({passenger.email})
            </li>
          ))}
        </ul>
        {!documents_done && (!documents || documents.length === 0) && (
          <div className="mt-4">
            <strong>Upload Documents:</strong>
            <Dropzone bookingId={id} onUploadSuccess={refreshBookingState} />
          </div>
        )}
      </div>
    );
  };

  const Dropzone = ({ bookingId, onUploadSuccess }) => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: async (acceptedFiles) => {
        const formData = new FormData();
        acceptedFiles.forEach((file) => {
          formData.append("documents", file);
        });

        try {
          await pb.collection("reservations").update(bookingId, formData);
          alert("Documents were successfully uploaded.");
          if (onUploadSuccess) onUploadSuccess(); // Trigger refresh after successful upload
        } catch (error) {
          console.error("Error uploading documents:", error);
          alert("An error occurred while uploading documents.");
        }
      },
    });

    return (
      <div
        {...getRootProps()}
        className="border-dashed border-2 border-gray-300 p-4 mt-2 cursor-pointer"
      >
        <input {...getInputProps()} />
        <p>Drag and drop or click to upload documents</p>
      </div>
    );
  };

  const handlePaymentMethodChange = async (e, bookingId) => {
    const newPaymentMethod = e.target.value;
    try {
      await pb.collection("reservations").update(bookingId, {
        payment_method: newPaymentMethod,
      });
      // Update local state
      setBookings((prevBookings) =>
        prevBookings.map((booking) =>
          booking.id === bookingId
            ? { ...booking, payment_method: newPaymentMethod }
            : booking
        )
      );
    } catch (error) {
      console.error("Error updating payment method:", error);
      alert("An error occurred while updating the payment method.");
    }
  };

  return (
    <Base>
      <div className="container mx-auto px-4 py-6">
  <h1 className="text-2xl font-bold mb-4">My Bookings</h1>
  {!reservation_id && (
    <div className="flex items-center space-x-4 mb-6">
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="p-2 border border-gray-300 rounded-md w-full"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            fetchBookings(null);
          }
        }}
      />
      <button
        onClick={() => fetchBookings(null)}
        className="bg-blue-600 text-white px-4 py-2 rounded-md"
      >
        Search
      </button>
    </div>
  )}
  {hasSearched && bookings.length === 0 && (
    <p>No bookings found for the provided email or ID.</p>
  )}
  {bookings.length > 0 &&
    bookings.map((booking) => (
      <div key={booking.id} className="mb-4">
        <div
          onClick={() =>
            setSelectedBooking(
              selectedBooking?.id === booking.id ? null : booking
            )
          }
          className="p-4 bg-white shadow-md rounded-md cursor-pointer"
        >
          <p>
            <strong>Trip Name:</strong> {booking.expand?.tour?.name || "Unknown Tour"}
          </p>
        </div>
        {selectedBooking?.id === booking.id && (
          <div className="p-4 bg-gray-100 rounded-md shadow-md">
            <BookingDetails booking={booking} />
            <div className="mt-4">
              <label htmlFor={`payment-method-${booking.id}`} className="block mb-2">
                <strong>Change Payment Method:</strong>
              </label>
              <select
                id={`payment-method-${booking.id}`}
                value={booking.payment_method}
                onChange={(e) => handlePaymentMethodChange(e, booking.id)}
                className="p-2 border border-gray-300 rounded-md w-full"
              >
                <option value="card">Card</option>
                <option value="bank_transfer">Bank Transfer</option>
              </select>
            </div>
            {(booking.payment_status === "pending" ||
              booking.payment_status === "failed") && (
              <button
                onClick={() => handlePayment(booking.id)}
                className="mt-4 bg-green-600 text-white px-4 py-2 rounded-md"
              >
                Pay Now
              </button>
            )}
          </div>
        )}
      </div>
    ))}
  <p className="text-center mt-6">
    Are you a travel agency?{" "}
    <a
      href="/company/login"
      className="text-blue-600 underline"
    >
      Login here
    </a>
  </p>
</div>

    </Base>
  );  
};

export default MyBookings;
