import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PocketBase from "pocketbase";
import Lightbox from "react-image-lightbox"; // Import Lightbox
import "react-image-lightbox/style.css"; // Štýly pre Lightbox
import Modal from "./Modal";
import Base from "./Base";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const ReservationDetails = () => {
  const [reservation, setReservation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);


  const navigate = useNavigate();
  const { id } = useParams();

  const checkAuth = () => {
    if (!pb.authStore.isValid) {
      navigate("/admin/auth");
    }
  };

  const fetchReservation = async () => {
    try {
      setIsLoading(true);
      const record = await pb.collection("reservations").getOne(id, {
        expand: "tour,cabin",
      });
      setReservation(record);
    } catch (error) {
      console.error("Error fetching reservation details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectDocuments = async () => {
    try {
      await pb.collection("reservations").update(id, {
        documents: [], // Vyprázdnime pole dokumentov
        documents_done: false, // Resetujeme stav dokumentov
      });
      alert("Documents have been rejected and removed.");
      fetchReservation(); // Aktualizujeme údaje rezervácie
      setIsRejectModalOpen(false); // Zatvoríme modálne okno
    } catch (error) {
      console.error("Error rejecting documents:", error);
      alert("An error occurred while rejecting documents.");
    }
  };
  

  const handleMarkDocumentsDone = async () => {
    try {
      await pb.collection("reservations").update(id, {
        documents_done: true,
      });
      fetchReservation();
      setIsConfirmModalOpen(false);
    } catch (error) {
      console.error("Error updating reservation:", error);
    }
  };

  useEffect(() => {
    checkAuth();
    fetchReservation();
  }, [id]);

  const documentUrls =
    reservation?.documents?.map(
      (doc) =>
        `${process.env.REACT_APP_POCKETBASE_URL}/api/files/reservations/${reservation.id}/${doc}`
    ) || [];

  const checkImageLoaded = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => reject(false);
      img.src = url;
    });
  };

  const openLightbox = async (index) => {
    try {
      const isLoaded = await checkImageLoaded(documentUrls[index]);
      if (isLoaded) {
        setCurrentImageIndex(index);
        setIsLightboxOpen(true);
      }
    } catch {
      console.error("Image not loaded:", documentUrls[index]);
    }
  };

  const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  useEffect(() => {
    preloadImages(documentUrls); // Preload obrázkov dokumentov
  }, [documentUrls]);

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const moveNext = () => {
    if (documentUrls.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % documentUrls.length);
    }
  };

  const movePrev = () => {
    if (documentUrls.length > 0) {
      setCurrentImageIndex(
        (prevIndex) =>
          (prevIndex - 1 + documentUrls.length) % documentUrls.length
      );
    }
  };

  return (
    <Base>
      <div className="p-4">
        <button
          onClick={() => navigate(-1)}
          className="text-blue-500 underline mb-4"
        >
          Go Back
        </button>

        {isLoading ? (
          <p>Loading reservation details...</p>
        ) : reservation ? (
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h1 className="text-2xl font-bold mb-4">Reservation Details</h1>
            <p>
              <strong>First Name:</strong> {reservation.first_name}
            </p>
            <p>
              <strong>Last Name:</strong> {reservation.last_name}
            </p>
            <p>
              <strong>Email:</strong> {reservation.email}
            </p>
            <p>
              <strong>Tour:</strong> {reservation.expand?.tour?.name || "N/A"}
            </p>
            <p>
              <strong>Cabin:</strong> {reservation.expand?.cabin?.name || "N/A"}
            </p>
            <p>
              <strong>Created At:</strong>{" "}
              {new Date(reservation.created).toLocaleString()}
            </p>
            <p>
              <strong>Documents Status:</strong>{" "}
              <span
                className={`font-bold ${
                  reservation.documents_done ? "text-green-600" : "text-red-600"
                }`}
              >
                {reservation.documents_done ? "Completed" : "Pending"}
              </span>
            </p>

            <div className="mt-4">
              <strong>Documents:</strong>
              <div className="flex flex-wrap gap-4 mt-2">
                {documentUrls.length > 0 ? (
                  documentUrls.map((url, index) => (
                    <img
                      key={index}
                      src={`${url}?thumb=200x200`}
                      alt={`Document ${index + 1}`}
                      className="w-32 h-32 object-cover rounded-lg cursor-pointer shadow-md hover:shadow-lg"
                      onClick={() => openLightbox(index)}
                    />
                  ))
                ) : (
                  <p>No documents available.</p>
                )}
              </div>
            </div>

            {!reservation.documents_done && documentUrls.length > 0 && (
              <button
              onClick={() => setIsRejectModalOpen(true)} // Otvorí modálne okno
              className="bg-red-500 text-white px-4 py-2 mr-4 rounded-lg hover:bg-red-600"
              >
                Reject Documents
              </button>
            )}

            {!reservation.documents_done && (
              <button
                onClick={() => setIsConfirmModalOpen(true)}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
              >
                Mark Documents as Completed
              </button>
            )}
          </div>
        ) : (
          <p>Reservation not found.</p>
        )}

        {isLightboxOpen && (
          <Lightbox
            mainSrc={documentUrls[currentImageIndex]}
            nextSrc={
              documentUrls[(currentImageIndex + 1) % documentUrls.length]
            }
            prevSrc={
              documentUrls[
                (currentImageIndex - 1 + documentUrls.length) %
                  documentUrls.length
              ]
            }
            onCloseRequest={closeLightbox}
            onMovePrevRequest={movePrev}
            onMoveNextRequest={moveNext}
          />
        )}

        {isRejectModalOpen && (
          <Modal onClose={() => setIsRejectModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">Confirm Reject Documents</h2>
            <p>Are you sure you want to reject and remove all uploaded documents?</p>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setIsRejectModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                onClick={handleRejectDocuments}
              >
                Confirm
              </button>
            </div>
          </Modal>
        )}

        {isConfirmModalOpen && (
          <Modal onClose={() => setIsConfirmModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">
              Confirm Documents Completion
            </h2>
            <p>Are you sure you want to mark the documents as completed?</p>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setIsConfirmModalOpen(false)}
                role="button"
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                onClick={handleMarkDocumentsDone}
                role="button"
              >
                Confirm
              </button>
            </div>
          </Modal>
        )}
      </div>
    </Base>
  );
};

export default ReservationDetails;
