import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import Modal from "./Modal"; // Jednoduchý Modal komponent
import Base from "./Base"; // Základné rozloženie
import { useDropzone } from "react-dropzone";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const Tours = () => {
  const [tours, setTours] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [ships, setShips] = useState([]);
  const [cabins, setCabins] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isCabinPricesModalOpen, setIsCabinPricesModalOpen] = useState(false);
  const [createdTourId, setCreatedTourId] = useState(null);
  const [cabinsPrices, setCabinsPrices] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [newTourData, setNewTourData] = useState({
    name: "",
    max_people: "",
    description: "",
    ship: "",
    duration: "",
    route: "",
    from: "",
  });

  const navigate = useNavigate();

  // Overenie prihlásenia
  const checkAuth = () => {
    if (!pb.authStore.isValid) {
      navigate("/admin/auth");
    }
  };

  const removeFile = (index) => {
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(updatedFiles);
  };

  // Načítanie lodí
  const fetchShips = async () => {
    try {
      const records = await pb.collection("ships").getFullList({
        sort: "name",
      });
      setShips(records);

      if (records.length > 0) {
        setNewTourData((prev) => ({ ...prev, ship: records[0].id }));
      }
    } catch (error) {
      console.error("Error fetching ships:", error);
    }
  };

  // Načítanie tours
  const fetchTours = async () => {
    try {
      const records = await pb.collection("tours").getFullList({
        sort: "-updated",
      });
      setTours(records);
    } catch (error) {
      console.error("Error fetching tours:", error);
    }
  };

  // Načítanie routes
  const fetchRoutes = async () => {
    try {
      const records = await pb.collection("routes").getFullList({
        sort: "name",
      });
      setRoutes(records);
    } catch (error) {
      console.error("Error fetching routes:", error);
    }
  };

  // Načítanie kabín pre danú loď
  const fetchCabins = async (shipId) => {
    try {
      const records = await pb.collection("ship_cabins").getFullList({
        filter: `ship='${shipId}'`,
        sort: "name",
      });
      setCabins(records);

      const initialCabinsPrices = {};
      records.forEach((cabin) => {
        initialCabinsPrices[cabin.id] = "";
      });
      setCabinsPrices(initialCabinsPrices);
    } catch (error) {
      console.error("Error fetching cabins:", error);
    }
  };

  // Upload obrázkov pomocou Dropzone
  const onDrop = useCallback((acceptedFiles) => {
    setUploadedFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });

  // Vytvorenie tour
  const handleCreateTour = async () => {
    try {
      const { name, max_people, description, ship, duration, route, from } =
        newTourData;

      if (!name || !max_people || !description || !duration || !route || !from) {
        return alert("Please fill in all required fields.");
      }

      if (uploadedFiles.length === 0) {
        return alert("Please upload at least one image.");
      }

      const formData = new FormData();
      formData.append("name", name);
      formData.append("max_people", max_people);
      formData.append("description", description);
      formData.append("duration", duration);
      formData.append("route", route);
      formData.append("from", from);
      formData.append("ship", ship);

      uploadedFiles.forEach((file) => {
        formData.append("photos", file);
      });

      const record = await pb.collection("tours").create(formData);
      setCreatedTourId(record.id); // Uložiť ID nového tour
      setIsCreateModalOpen(false);

      // Načítať kabíny po vytvorení tour
      fetchCabins(ship);

      setIsCabinPricesModalOpen(true); // Otvoriť modal na pridanie cien kabín
    } catch (error) {
      console.error("Error creating tour:", error);
    }
  };

  // Uloženie cien kabín
  const handleSaveCabinPrices = async () => {
    try {
      // Zakázať automatické rušenie žiadostí v PocketBase klientovi
      pb.autoCancellation(false);

      // Overiť, že všetky ceny sú vyplnené
      const missingPrices = Object.entries(cabinsPrices).some(
        ([, price]) => !price
      );
      if (missingPrices) {
        return alert("Please fill in all cabin prices.");
      }

      // Sekvenčne pridávať ceny kabín
      for (const [cabinId, price] of Object.entries(cabinsPrices)) {
        await pb.collection("cabins_prices").create({
          price: parseFloat(price),
          tour: createdTourId,
          cabin: cabinId,
        });
      }

      setIsCabinPricesModalOpen(false); // Zavrieť modal
      setCreatedTourId(null); // Vyčistiť ID tour
      fetchTours(); // Refresh tours list
    } catch (error) {
      console.error("Error saving cabin prices:", error);
      alert("An error occurred while saving cabin prices. Please try again.");
    } finally {
      // Zapnúť späť automatické rušenie žiadostí po dokončení
      pb.autoCancellation(true);
    }
  };


  useEffect(() => {
    checkAuth();
    fetchTours();
    fetchRoutes();
    fetchShips();
  }, []);

  return (
    <Base>
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Tours Management</h1>

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-4"
          onClick={() => setIsCreateModalOpen(true)}
        >
          Create New Tour
        </button>

        <ul className="space-y-2">
          {tours.map((tour) => (
            <li
              key={tour.id}
              className="flex justify-between items-center p-2 border rounded-lg"
            >
              <div>
                <p className="font-semibold">{tour.name}</p>
                <p className="text-sm text-gray-500">
                  {tour.description.length > 100
                    ? `${tour.description.substring(0, 100)}...`
                    : tour.description}
                </p>
              </div>
              <div className="flex space-x-2">
                <button
                  className="text-blue-500"
                  onClick={() => {
                    navigate(`/admin/tours/${tour.id}/reservations`);
                  }}
                >
                  Manage Reservations
                </button>
                <button
                  className="text-green-500"
                  onClick={() => {
                    navigate(`/admin/tours/${tour.id}/manage-prices`);
                  }}
                >
                  Manage Prices
                </button>
              </div>
            </li>
          ))}
        </ul>

        {/* Modal na vytvorenie tour */}
        {isCreateModalOpen && (
          <Modal onClose={() => setIsCreateModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">Create New Tour</h2>
            <div className="space-y-4">
              <input
                type="text"
                placeholder="Tour Name"
                value={newTourData.name}
                onChange={(e) =>
                  setNewTourData({ ...newTourData, name: e.target.value })
                }
                className="border p-2 rounded-lg w-full"
              />
              <input
                type="number"
                placeholder="Max People"
                value={newTourData.max_people}
                onChange={(e) =>
                  setNewTourData({ ...newTourData, max_people: e.target.value })
                }
                className="border p-2 rounded-lg w-full"
              />
              <textarea
                placeholder="Description"
                value={newTourData.description}
                onChange={(e) =>
                  setNewTourData({ ...newTourData, description: e.target.value })
                }
                className="border p-2 rounded-lg w-full"
              />
              <input
                type="number"
                placeholder="Duration (days)"
                value={newTourData.duration}
                onChange={(e) =>
                  setNewTourData({ ...newTourData, duration: e.target.value })
                }
                className="border p-2 rounded-lg w-full"
              />
              <select
                value={newTourData.route}
                onChange={(e) =>
                  setNewTourData({ ...newTourData, route: e.target.value })
                }
                className="border p-2 rounded-lg w-full"
              >
                <option value="" disabled>
                  Select Route
                </option>
                {routes.map((route) => (
                  <option key={route.id} value={route.id}>
                    {route.name}
                  </option>
                ))}
              </select>
              <select
                value={newTourData.ship}
                onChange={(e) => {
                  setNewTourData({ ...newTourData, ship: e.target.value });
                  fetchCabins(e.target.value); // Aktualizovať kabíny po zmene lode
                }}
                className="border p-2 rounded-lg w-full"
              >
                <option value="" disabled>
                  Select Ship
                </option>
                {ships.map((ship) => (
                  <option key={ship.id} value={ship.id}>
                    {ship.name}
                  </option>
                ))}
              </select>
              <input
                type="date"
                value={newTourData.from}
                onChange={(e) =>
                  setNewTourData({ ...newTourData, from: e.target.value })
                }
                className="border p-2 rounded-lg w-full"
              />

              {/* Drag and Drop Zone */}
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Upload Images
                </label>
                <div
                  {...getRootProps()}
                  className={`border-dashed border-2 p-4 rounded-lg text-center ${
                    isDragActive
                      ? "border-blue-500 bg-blue-50"
                      : uploadedFiles.length === 0
                      ? "border-red-500 bg-red-50"
                      : "border-gray-300"
                  }`}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here...</p>
                  ) : uploadedFiles.length === 0 ? (
                    <p className="text-red-500">No files uploaded.</p>
                  ) : (
                    <p>Drag & drop files here, or click to select files.</p>
                  )}
                </div>
                {uploadedFiles.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-4 p-2 border rounded-lg mb-2"
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`Preview ${index}`}
                      className="w-16 h-16 object-cover rounded-lg"
                    />
                    <p className="flex-1 text-sm text-gray-700">{file.name}</p>
                    <button
                      onClick={() => removeFile(index)}
                      className="bg-red-500 text-white px-2 py-1 rounded-lg"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setIsCreateModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg z-10"
                onClick={handleCreateTour}
                role="button"
              >
                Next
              </button>
            </div>
          </Modal>
        )}

        {/* Modal na pridanie cien kabín */}
        {isCabinPricesModalOpen && (
          <Modal onClose={() => setIsCabinPricesModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">Set Cabin Prices</h2>
            <div className="space-y-4">
              {cabins.map((cabin) => (
                <div key={cabin.id}>
                  <label className="block text-sm font-medium text-gray-700">
                    {cabin.name} (Beds: {cabin.beds})
                  </label>
                  <input
                    type="number"
                    placeholder="Enter price per person (€)"
                    value={cabinsPrices[cabin.id] || ""}
                    onChange={(e) =>
                      setCabinsPrices({
                        ...cabinsPrices,
                        [cabin.id]: e.target.value,
                      })
                    }
                    className="border p-2 rounded-lg w-full"
                  />
                </div>
              ))}
            </div>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setIsCabinPricesModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                onClick={handleSaveCabinPrices}
                role="button"
              >
                Save
              </button>
            </div>
          </Modal>
        )}
      </div>
    </Base>
  );
};

export default Tours;
