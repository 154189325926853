import React, { useEffect, useState } from "react";
import PocketBase from "pocketbase";
import Base from "./Base";
import { useNavigate } from "react-router-dom"; // Import navigate

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const Safari = () => {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize navigate

  const truncateDescription = (description, maxLength = 100) => {
    if (description.length > maxLength) {
      return description.slice(0, maxLength) + "...";
    }
    return description;
  };

  const getImageUrl = (collectionId, recordId, fileName) => {
    return `${process.env.REACT_APP_POCKETBASE_URL}/api/files/${collectionId}/${recordId}/${fileName}`;
  };

  useEffect(() => {
    const fetchToursWithPrices = async () => {
      try {
        // Fetch all tours
        const toursData = await pb.collection("tours").getFullList({
          expand: "ship",
        });

        // Fetch all cabin prices
        const pricesData = await pb.collection("cabins_prices").getFullList();

        // Map tours with the lowest price
        const toursWithPrices = toursData.map((tour) => {
          const relatedPrices = pricesData.filter(
            (price) => price.tour === tour.id
          );
          const minPrice =
            relatedPrices.length > 0
              ? Math.min(...relatedPrices.map((price) => price.price))
              : "N/A";
          return { ...tour, minPrice };
        });

        setTours(toursWithPrices);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchToursWithPrices();
  }, []);

  if (loading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  return (
    <Base>
      <div className="container mx-auto px-4 py-10">
        <h1 className="text-3xl font-bold text-center text-blue-600 mb-10">
          Planned Safari Trips
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {tours.map((tour) => (
            <div
              key={tour.id}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <img
                src={
                  tour.photos?.[0]
                    ? getImageUrl(tour.collectionId, tour.id, tour.photos[0])
                    : "https://via.placeholder.com/400"
                }
                alt={tour.name}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h2 className="text-xl font-bold text-gray-800">{tour.name}</h2>
                <p className="text-gray-600 text-sm">
                  {truncateDescription(tour.description)}
                </p>
                <p className="mt-2 text-blue-600 font-bold">
                  Starting from:{" "}
                  {tour.minPrice === "N/A"
                    ? "Not available"
                    : `${tour.minPrice} €`}
                </p>
                <button
                onClick={() => navigate(`/safari/${tour.id}`)}
                className="mt-4 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                >
                View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Base>
  );
};

export default Safari;
