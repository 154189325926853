import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import Modal from "./Modal"; // Komponent pre modálne okno
import Base from "./Base";
import { useDropzone } from "react-dropzone";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const ShipCabins = ({ ship_id }) => {
  const [cabins, setCabins] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedCabin, setSelectedCabin] = useState(null);
  const [newCabin, setNewCabin] = useState({
    name: "",
    beds: 0,
    description: "",
    count: 0,
    photos: [],
  });
  const navigate = useNavigate();

  // Overenie, či je používateľ prihlásený
  const checkAuth = () => {
    if (!pb.authStore.isValid) {
      navigate("/admin/auth");
    }
  };

  // Fetch cabins for the ship
  const fetchCabins = async () => {
    try {
      const records = await pb.collection("ship_cabins").getFullList({
        filter: `ship='${ship_id}'`,
        sort: "-updated",
      });
      setCabins(records);
    } catch (error) {
      console.error("Error fetching cabins:", error);
    }
  };

  // Create a new cabin
  const handleCreateCabin = async () => {
    const { name, beds, description, count, photos } = newCabin;
    if (!name || beds <= 0 || !description || count <= 0 || photos.length === 0) {
      alert("Please fill in all required fields correctly, including at least one photo.");
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("beds", beds);
    formData.append("description", description);
    formData.append("count", count);
    formData.append("ship", ship_id);
    photos.forEach((photo) => {
      formData.append("photos", photo);
    });

    try {
      await pb.collection("ship_cabins").create(formData);
      setIsCreateModalOpen(false);
      setNewCabin({ name: "", beds: 0, description: "", count: 0, photos: [] });
      fetchCabins();
    } catch (error) {
      console.error("Error creating cabin:", error);
    }
  };

  // Delete a cabin
  const handleDeleteCabin = async () => {
    if (!selectedCabin) return;

    try {
      await pb.collection("ship_cabins").delete(selectedCabin.id);
      setIsDeleteModalOpen(false);
      setSelectedCabin(null);
      fetchCabins();
    } catch (error) {
      console.error("Error deleting cabin:", error);
    }
  };

  // Handle dropzone for photo upload
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setNewCabin({ ...newCabin, photos: [...newCabin.photos, ...acceptedFiles] });
    },
  });

  // Remove photo
  const removePhoto = (index) => {
    const updatedPhotos = newCabin.photos.filter((_, i) => i !== index);
    setNewCabin({ ...newCabin, photos: updatedPhotos });
  };

  useEffect(() => {
    checkAuth();
    fetchCabins();
  }, [ship_id]);

  return (
    <Base>
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Manage Cabins for Ship</h1>

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-4"
          onClick={() => setIsCreateModalOpen(true)}
        >
          Add New Cabin
        </button>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {cabins.map((cabin) => (
            <div
              key={cabin.id}
              className="border rounded-lg p-4 shadow-md relative"
            >
              <h3 className="text-lg font-semibold">{cabin.name}</h3>
              <p className="text-sm text-gray-600">
                Beds: {cabin.beds} | Count: {cabin.count}
              </p>
              <p className="text-sm text-gray-600 mt-2">{cabin.description}</p>
              <button
                className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 text-xs rounded"
                onClick={() => {
                  setSelectedCabin(cabin);
                  setIsDeleteModalOpen(true);
                }}
              >
                Delete
              </button>
            </div>
          ))}
        </div>

        {/* Create Cabin Modal */}
        {isCreateModalOpen && (
          <Modal onClose={() => setIsCreateModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">Add New Cabin</h2>

            {/* Názov kabíny */}
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Cabin Name
            </label>
            <input
              type="text"
              placeholder="Enter cabin name"
              value={newCabin.name}
              onChange={(e) =>
                setNewCabin({ ...newCabin, name: e.target.value })
              }
              className="border p-2 rounded-lg w-full mb-4"
            />

            {/* Počet postelí */}
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Number of Beds
            </label>
            <input
              type="number"
              placeholder="Enter number of beds"
              value={newCabin.beds || ""}
              onChange={(e) =>
                setNewCabin({ ...newCabin, beds: Number(e.target.value) })
              }
              className="border p-2 rounded-lg w-full mb-4"
            />

            {/* Počet kabín */}
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Number of Cabins
            </label>
            <input
              type="number"
              placeholder="Enter number of cabins"
              value={newCabin.count || ""}
              onChange={(e) =>
                setNewCabin({ ...newCabin, count: Number(e.target.value) })
              }
              className="border p-2 rounded-lg w-full mb-4"
            />

            {/* Popis kabíny */}
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Description
            </label>
            <textarea
              placeholder="Enter cabin description"
              value={newCabin.description}
              onChange={(e) =>
                setNewCabin({ ...newCabin, description: e.target.value })
              }
              className="border p-2 rounded-lg w-full mb-4"
            />

            {/* Dropzone pre nahratie fotky */}
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Upload Photos (Required)
            </label>
            <div
              {...getRootProps()}
              className={`p-4 rounded-lg mb-4 cursor-pointer border-2 ${
                newCabin.photos.length === 0
                  ? "border-red-500 text-red-500"
                  : "border-black text-black"
              }`}
            >
              <input {...getInputProps()} />
              {newCabin.photos.length > 0 ? (
                <p>Drag & drop files here, or click to select files.</p>
              ) : (
                <p>No files uploaded.</p>
              )}
            </div>

            {/* Zobrazenie nahraných fotiek s možnosťou ich odstrániť */}
            {newCabin.photos.length > 0 && (
              <div className="mb-4">
                {newCabin.photos.map((photo, index) => (
                  <div key={index} className="flex items-center gap-2 mb-2">
                    <img
                      src={URL.createObjectURL(photo)}
                      alt={`Preview ${index}`}
                      className="w-16 h-16 object-cover rounded-lg"
                    />
                    <p className="flex-1">{photo.name}</p>
                    <button
                      className="bg-red-500 text-white px-2 py-1 rounded-lg z-10"
                      onClick={() => removePhoto(index)}
                      role="button"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            )}

            {/* Tlačidlá pre pridanie alebo zrušenie */}
            <div className="flex justify-end space-x-2">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setIsCreateModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                onClick={handleCreateCabin}
              >
                Add Cabin
              </button>
            </div>
          </Modal>
        )}

        {/* Delete Confirmation Modal */}
        {isDeleteModalOpen && (
          <Modal onClose={() => setIsDeleteModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete the cabin: {selectedCabin?.name}?</p>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
                onClick={handleDeleteCabin}
              >
                Delete
              </button>
            </div>
          </Modal>
        )}
      </div>
    </Base>
  );
};

export default ShipCabins;
