import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Modal from './Modal'; // Jednoduchý Modal komponent
import Base from './Base';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const RoutesAdmin = () => {
  const [routes, setRoutes] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [routeName, setRouteName] = useState('');
  const navigate = useNavigate();

  // Overenie, či je používateľ prihlásený
  const checkAuth = () => {
    if (!pb.authStore.isValid) {
      navigate('/admin/auth'); // Ak nie je prihlásený, presmeruj na prihlasovaciu stránku
    }
  };

  // Fetch all routes
  const fetchRoutes = async () => {
    try {
      const records = await pb.collection('routes').getFullList({
        sort: '-updated', // Zoradenie podľa poslednej aktualizácie
      });
      setRoutes(records);
    } catch (error) {
      console.error('Error fetching routes:', error);
    }
  };

  // Create a new route
  const handleCreateRoute = async () => {
    if (!routeName) return;
    try {
      await pb.collection('routes').create({ name: routeName });
      setIsCreateModalOpen(false);
      setRouteName('');
      fetchRoutes();
    } catch (error) {
      console.error('Error creating route:', error);
    }
  };

  // Update an existing route
  const handleUpdateRoute = async () => {
    if (!selectedRoute || !routeName) return;
    try {
      await pb.collection('routes').update(selectedRoute.id, { name: routeName });
      setIsEditModalOpen(false);
      setSelectedRoute(null);
      setRouteName('');
      fetchRoutes();
    } catch (error) {
      console.error('Error updating route:', error);
    }
  };

  // Delete a route
  const handleDeleteRoute = async () => {
    if (!selectedRoute) return;
    try {
      await pb.collection('routes').delete(selectedRoute.id);
      setIsDeleteModalOpen(false);
      setSelectedRoute(null);
      fetchRoutes();
    } catch (error) {
      console.error('Error deleting route:', error);
    }
  };

  useEffect(() => {
    checkAuth();
    fetchRoutes();
  }, []);

  return (
    <Base>
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Routes Management</h1>

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-4"
          onClick={() => {
            setIsCreateModalOpen(true);
            setRouteName('');
          }}
        >
          Create New Route
        </button>

        <ul className="space-y-2">
          {routes.map((route) => (
            <li
              key={route.id}
              className="flex justify-between items-center p-2 border rounded-lg"
            >
              <span>{route.name}</span>
              <div className="flex space-x-2">
                <button
                  className="text-blue-500"
                  onClick={() => {
                    setSelectedRoute(route);
                    setRouteName(route.name);
                    setIsEditModalOpen(true);
                  }}
                >
                  Edit
                </button>
                <button
                  className="text-red-500"
                  onClick={() => {
                    setSelectedRoute(route);
                    setIsDeleteModalOpen(true);
                  }}
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>

        {/* Create Route Modal */}
        {isCreateModalOpen && (
          <Modal onClose={() => setIsCreateModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">Create New Route</h2>
            <input
              type="text"
              placeholder="Route name"
              value={routeName}
              onChange={(e) => setRouteName(e.target.value)}
              className="border p-2 rounded-lg w-full mb-4"
            />
            <div className="flex justify-end space-x-2">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setIsCreateModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                onClick={handleCreateRoute}
              >
                Create
              </button>
            </div>
          </Modal>
        )}

        {/* Edit Route Modal */}
        {isEditModalOpen && (
          <Modal onClose={() => setIsEditModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">Edit Route</h2>
            <input
              type="text"
              placeholder="Route name"
              value={routeName}
              onChange={(e) => setRouteName(e.target.value)}
              className="border p-2 rounded-lg w-full mb-4"
            />
            <div className="flex justify-end space-x-2">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setIsEditModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg z-10"
                onClick={handleUpdateRoute}
                role="button"
              >
                Save
              </button>
            </div>
          </Modal>
        )}

        {/* Delete Confirmation Modal */}
        {isDeleteModalOpen && (
          <Modal onClose={() => setIsDeleteModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete route: {selectedRoute?.name}?</p>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
                onClick={handleDeleteRoute}
              >
                Delete
              </button>
            </div>
          </Modal>
        )}
      </div>
    </Base>
  );
};

export default RoutesAdmin;
