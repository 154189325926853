import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const Base = ({ children }) => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleLogout = () => {
    pb.authStore.clear(); // Clear PocketBase authentication
    console.log("User logged out");
    navigate('/admin/auth'); // Redirect to auth page
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`fixed inset-y-0 left-0 bg-white shadow-md h-full transform transition-transform duration-300 lg:static lg:translate-x-0 z-50 w-80 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-4 text-center bg-blue-600 text-white font-bold text-lg">
          Admin Panel
        </div>
        <nav className="mt-6 space-y-2">
          <NavLink
            to="/admin/dashboard"
            className={({ isActive }) =>
              `block px-4 py-2 rounded-lg mx-4 ${
                isActive
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`
            }
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/admin/locations"
            className={({ isActive }) =>
              `block px-4 py-2 rounded-lg mx-4 ${
                isActive
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`
            }
          >
            Locations
          </NavLink>
          <NavLink
            to="/admin/tours"
            className={({ isActive }) =>
              `block px-4 py-2 rounded-lg mx-4 ${
                isActive
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`
            }
          >
            Tours
          </NavLink>
          <NavLink
            to="/admin/reviews"
            className={({ isActive }) =>
              `block px-4 py-2 rounded-lg mx-4 ${
                isActive
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`
            }
          >
            Reviews
          </NavLink>
          <NavLink
            to="/admin/gallery"
            className={({ isActive }) =>
              `block px-4 py-2 rounded-lg mx-4 ${
                isActive
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`
            }
          >
            Gallery
          </NavLink>
          <NavLink
            to="/admin/bank-details"
            className={({ isActive }) =>
              `block px-4 py-2 rounded-lg mx-4 ${
                isActive
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`
            }
          >
            Bank Details
          </NavLink>
          <NavLink
            to="/admin/routes"
            className={({ isActive }) =>
              `block px-4 py-2 rounded-lg mx-4 ${
                isActive
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`
            }
          >
            Routes
          </NavLink>
          <NavLink
            to="/admin/ships"
            className={({ isActive }) =>
              `block px-4 py-2 rounded-lg mx-4 ${
                isActive
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`
            }
          >
            Ships
          </NavLink>
          <NavLink
            to="/"
            className={({ isActive }) =>
              `block px-4 py-2 rounded-lg mx-4 ${
                isActive
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-gray-200"
              }`
            }
          >
            Visit Website
          </NavLink>
          <button
            onClick={handleLogout}
            className="block px-4 py-2 rounded-lg mx-4 text-left text-gray-700 hover:bg-red-500 hover:text-white transition"
            style={{ width: "calc(100% - 2rem)" }}
          >
            Logout
          </button>
        </nav>
      </aside>

      {/* Main Content */}
      <div className="flex-1 flex flex-col">
        <header className="bg-white shadow-md p-4 flex items-center justify-between lg:hidden sticky top-0 z-50">
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="text-gray-600 hover:text-gray-900"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
          <span className="text-lg font-bold">Admin Panel</span>
        </header>
        <main className="flex-1 p-6 overflow-y-auto">
          {children}
        </main>
      </div>

      {/* Overlay for mobile */}
      {isSidebarOpen && (
        <div
          onClick={() => setIsSidebarOpen(false)}
          className="fixed inset-0 bg-transparent z-40 lg:hidden"
        ></div>
      )}
    </div>
  );
};

export default Base;
