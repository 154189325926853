import React from "react";
import { useEffect } from "react";
import Base from "./Base";
import PocketBase from 'pocketbase';
import { useNavigate } from 'react-router-dom';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const Dashboard = () => {
  const navigate = useNavigate();

  // Overenie, či je používateľ prihlásený
  const checkAuth = () => {
    if (!pb.authStore.isValid) {
      navigate('/admin/auth'); // Ak nie je prihlásený, presmeruj na prihlasovaciu stránku
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);


  return (
    <Base>
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
      <p>Welcome to the admin dashboard.</p>
    </Base>
  );
};

export default Dashboard;
