import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import Base from "./Base";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
pb.autoCancellation(false);

const Reservation = () => {
  const { tourId, cabinId } = useParams();
  const navigate = useNavigate();
  const [tour, setTour] = useState(null);
  const [cabin, setCabin] = useState(null);
  const [pricePerPerson, setPricePerPerson] = useState(0);
  const [cabinBasePrice, setCabinBasePrice] = useState(0);
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [passengerCount, setPassengerCount] = useState(1);
  const [availableBeds, setAvailableBeds] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("bank_transfer");
  const [isSolo, setIsSolo] = useState(false);
  const [isTravelAgency, setIsTravelAgency] = useState(false);

  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        const authUser = pb.authStore.model;
        if (authUser) {
          setIsTravelAgency(true);
        }
      } catch (error) {
        console.error("Error checking user status:", error);
      }
    };
    checkUserStatus();
  }, []);

  useEffect(() => {
    const fetchTourAndCabin = async () => {
      try {
        if (!tourId || !cabinId) {
          console.error("Missing tourId or cabinId");
          return;
        }
    
        // Načítanie údajov o zájazde a kabíne
        const tourData = await pb.collection("tours").getOne(tourId, {
          expand: "route,ship",
        });
        const cabinData = await pb.collection("ship_cabins").getOne(cabinId, {
          expand: "ship",
        });
    
        if (!tourData || !cabinData) {
          console.error("Missing tour or cabin data");
          return;
        }
    
        setTour(tourData);
        setCabin(cabinData);
    
        // Načítanie existujúcich rezervácií
        const reservations = await pb.collection("reservations").getFullList({
          filter: `cabin.id = "${cabinId}" && (status = "pending" || status = "accepted")`,
        });
    
        // Výpočet rezervovaných postelí, vrátane sólo cestujúcich
        const reservedBeds = reservations.reduce((sum, res) => {
          const soloTravelers = res.passengers?.filter((passenger) => passenger.travel_alone).length || 0;
          return sum + (res.passenger_count || 0) + soloTravelers; // Sólo cestujúci zaberajú celú kajutu
        }, 0);
    
        // Celkový počet postelí
        const totalBeds = cabinData.beds * cabinData.count;
    
        // Aktualizácia dostupných miest
        setAvailableBeds(totalBeds - reservedBeds);
    
        // Načítanie ceny kajuty
        const cabinPrice = await pb
          .collection("cabins_prices")
          .getFirstListItem(`tour = "${tourId}" && cabin = "${cabinId}"`);
    
        setPricePerPerson(cabinPrice.price);
        setCabinBasePrice(cabinPrice.price);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    
    fetchTourAndCabin();
  }, [tourId, cabinId]);

  useEffect(() => {
    setFormData((prev) => {
      const newFormData = Array.from({ length: passengerCount }, (_, index) => {
        const existingData = prev[index] || {};
        return {
          first_name: existingData.first_name || "",
          last_name: existingData.last_name || "",
          email: existingData.email || "",
          is_healthy: existingData.is_healthy || false,
          is_payer: !isTravelAgency && index === 0, // Prvý pasažier bude označený iba ak nie je cestovná kancelária
          travel_alone: passengerCount === 1 && isSolo, // Pridá travel_alone iba v prípade sólo cestujúceho
        };
      });
      return newFormData;
    });
  }, [passengerCount]);

  const handlePassengerCountChange = (e) => {
    const count = parseInt(e.target.value, 10);
    if (count <= availableBeds) {
      setPassengerCount(count);
      if (count > 1) {
        setIsSolo(false);
      }
    } else {
      alert("The number of people exceeds the available number of beds.");
    }
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    setFormData((prev) =>
      prev.map((data, i) =>
        i === index ? { ...data, [name]: value } : data
      )
    );
  };

  const handleSoloToggle = () => {
    setIsSolo((prev) => {
      const newIsSolo = !prev;
      setFormData((prevFormData) =>
        prevFormData.map((data, index) => ({
          ...data,
          travel_alone: passengerCount === 1 && newIsSolo, // Pridá travel_alone iba ak je sólo cestujúci
        }))
      );
      return newIsSolo;
    });
  };

  const handleHealthyChange = (index, value) => {
    setFormData((prev) =>
      prev.map((data, i) => ({
        ...data,
        is_healthy: i === index ? value : data.is_healthy,
      }))
    );
  };

  const handlePayerSelection = (index) => {
    setFormData((prev) =>
      prev.map((data, i) => ({
        ...data,
        is_payer: i === index, // Iba aktuálne vybraný index bude platca
      }))
    );
  };

  const handleReservation = async () => {
    if (!formData.every((data) => data.is_healthy)) {
      alert("All passengers must confirm that they are healthy.");
      return;
    }
  
    if (formData.some((data) => !data.first_name || !data.last_name || !data.email)) {
      alert("All fields for all passengers are required.");
      return;
    }
  
    setLoading(true);
  
    try {
      const authUser = pb.authStore.model;
  
      // Výpočet celkovej sumy vrátane príplatkov za sólo cestovanie
      const soloTravelers = formData.filter((data) => data.travel_alone).length;
      const soloTravelSurcharge = soloTravelers * cabinBasePrice * 0.5;
      const totalAmount = pricePerPerson * passengerCount + soloTravelSurcharge;
  
      // Pripravenie dát rezervácie
      const reservationData = {
        tour: tourId,
        cabin: cabinId,
        email: isTravelAgency && authUser
          ? authUser.email
          : formData.find((data) => data.is_payer)?.email || formData[0].email,
        passenger_count: passengerCount,
        passengers: formData,
        total_amount: totalAmount,
        documents_done: false,
        payment_status: "pending",
        payment_method: paymentMethod,
        status: "pending",
      };
  
      const createdReservation = await pb.collection("reservations").create(reservationData);
  
      // Záznam pre cestovnú kanceláriu
      if (isTravelAgency && authUser) {
        await pb.collection("custom_reservations").create({
          owner: authUser.id,
          reservation: createdReservation.id,
          amount_to_pay: totalAmount,
          payment_method: paymentMethod,
        });
      }
  
      alert("The reservation was successfully created!");
      navigate(`/payment/${createdReservation.id}`);
    } catch (error) {
      console.error("Error creating reservation:", error);
      alert("An error occurred while creating the reservation.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Base>
      <div className="container mx-auto px-4 py-8">
        {tour && cabin ? (
          <div>
            <h1 className="text-3xl font-bold text-blue-600 mb-6">Reservation</h1>
            <div className="bg-white p-4 shadow-md rounded mb-4">
              <h2 className="text-2xl font-bold">{tour.name}</h2>
              <p className="mt-2">Duration: {tour.duration} days</p>
              <p>Ship: {tour.expand?.ship?.name}</p>
            </div>
            <div className="bg-white p-4 shadow-md rounded mb-4">
              <h2 className="text-2xl font-bold">{cabin.name}</h2>
              <p className="text-gray-700">{cabin.description}</p>
              <p>Number of beds: {cabin.beds}</p>
              <p>Available: {availableBeds} beds</p>
            </div>
            <div className="mb-4">
              <label>Payment Method:</label>
              <select
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                className="w-full p-2 border rounded"
              >
                <option value="bank_transfer">Bank Transfer</option>
                <option value="card">Card</option>
              </select>
            </div>
            {isTravelAgency && (
              <div className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={isTravelAgency}
                    readOnly
                  />
                  <span className="ml-2">Booking as a travel agency</span>
                </label>
              </div>
            )}
            <div className="bg-gray-100 p-4 shadow-md rounded mb-4">
              <h3 className="text-xl font-bold mb-4">Number of Passengers</h3>
              <input
                type="number"
                value={passengerCount}
                onChange={handlePassengerCountChange}
                min="1"
                max={availableBeds}
                className="w-full p-2 border rounded"
              />
            </div>
            {passengerCount === 1 && (
              <div className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={isSolo}
                    onChange={handleSoloToggle}
                  />
                  <span className="ml-2">
                    I want to travel alone (+50% price of cabin)
                  </span>
                </label>
              </div>
            )}
           {formData.map((data, index) => (
            <div key={index} className="bg-gray-100 p-4 shadow-md rounded mb-4">
              <h3 className="text-xl font-bold mb-4">Passenger {index + 1} Details</h3>
              <form>
                <div className="mb-4">
                  <label className="block mb-2">First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    className="w-full p-2 border rounded"
                    value={data.first_name}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    className="w-full p-2 border rounded"
                    value={data.last_name}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="w-full p-2 border rounded"
                    value={data.email}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>
                <div className="mb-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={data.is_healthy}
                      onChange={(e) => handleHealthyChange(index, e.target.checked)}
                    />
                    <span className="ml-2">
                      I confirm that I am healthy and can participate.
                    </span>
                  </label>
                </div>
                {!isTravelAgency && passengerCount > 0 && (
                  <div className="mb-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        checked={data.is_payer}
                        onChange={() => handlePayerSelection(index)}
                      />
                      <span className="ml-2">Mark as Payer</span>
                    </label>
                  </div>
                )}
                {/* Dynamické zobrazenie checkboxu "I want to travel alone" */}
                {passengerCount > cabin.beds && (
                  <div className="mb-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        checked={data.travel_alone || false}
                        onChange={(e) =>
                          setFormData((prev) =>
                            prev.map((item, i) =>
                              i === index
                                ? { ...item, travel_alone: e.target.checked }
                                : item
                            )
                          )
                        }
                      />
                      <span className="ml-2">
                        I want to travel alone in my cabin (+50% price)
                      </span>
                    </label>
                  </div>
                )}
              </form>
            </div>
          ))}
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded"
              onClick={handleReservation}
              disabled={loading}
            >
              {loading ? "Processing..." : "Confirm Reservation"}
            </button>
          </div>
        ) : (
          <p>Loading data...</p>
        )}
      </div>
    </Base>
  );
};

export default Reservation;
