import React, { useEffect, useState } from "react";
import PocketBase from "pocketbase";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const SafariFeatured = () => {
  const [safaris, setSafaris] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSafaris = async () => {
      try {
        const fetchedSafaris = await pb.collection("tours").getFullList({
          expand: "route,ship",
        });

        const safarisWithDetails = await Promise.all(
          fetchedSafaris.slice(0, 3).map(async (safari) => {
            const cabins = await pb.collection("ship_cabins").getFullList({
              filter: `ship = "${safari.expand.ship?.id}"`,
              expand: "ship",
            });

            const reservations = await pb.collection("reservations").getFullList({
              filter: `tour = "${safari.id}" && (status = "pending" || status = "accepted")`,
            });

            let totalBeds = 0;
            let reservedBeds = 0;

            for (const cabin of cabins) {
              const cabinBeds = cabin.beds * cabin.count;
              totalBeds += cabinBeds;

              const cabinReservations = reservations.filter(
                (res) => res.cabin === cabin.id
              );

              const soloTravelers = cabinReservations.reduce(
                (sum, res) =>
                  sum +
                  (res.passengers?.filter((p) => p.travel_alone).length || 0),
                0
              );

              reservedBeds += cabinReservations.reduce(
                (sum, res) => sum + (res.passenger_count || 0),
                soloTravelers
              );
            }

            const availableBeds = totalBeds - reservedBeds;

            const cabinPrices = await Promise.all(
              cabins.map(async (cabin) => {
                try {
                  const priceData = await pb
                    .collection("cabins_prices")
                    .getFirstListItem(`tour = "${safari.id}" && cabin = "${cabin.id}"`);
                  return priceData.price || Infinity;
                } catch {
                  return Infinity; // Ak cena nie je dostupná, vráti "Infinity"
                }
              })
            );

            const lowestPrice = Math.min(...cabinPrices);

            const firstImage = safari.photos?.[0]
              ? `${process.env.REACT_APP_POCKETBASE_URL}/api/files/tours/${safari.id}/${safari.photos[0]}`
              : "https://via.placeholder.com/400x250";

            return {
              ...safari,
              availableBeds,
              totalBeds,
              firstImage,
              lowestPrice: isFinite(lowestPrice) ? lowestPrice : null, // Nastaví na null, ak nie je platná cena
            };
          })
        );

        setSafaris(safarisWithDetails);
      } catch (error) {
        console.error("Error fetching safaris:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSafaris();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

    return (
        <section className="container mx-auto px-4 py-12">
          <h2 className="text-3xl font-bold text-center mb-8">Recommended Safaris</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {safaris.map((safari, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md hover:shadow-lg transition"
              >
                <img
                  src={safari.firstImage}
                  alt={safari.name}
                  className="rounded-t-lg h-64 w-full object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-800">{safari.name}</h3>
                  <p className="text-gray-600 mt-2">
                    Starts on:{" "}
                    <span className="font-medium">
                    {new Date(safari.from).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    })}
                    </span>
                  </p>
                  <p className="text-gray-600 mt-2">
                    Capacity:{" "}
                    <span className="font-medium">
                      {safari.totalBeds - safari.availableBeds}/{safari.totalBeds}
                    </span>
                  </p>
                  <p className="text-gray-800 text-lg font-bold mt-4">
                    {safari.lowestPrice !== null ? (
                        <span>
                        From {" "}
                        {Number.isInteger(safari.lowestPrice)
                            ? safari.lowestPrice
                            : safari.lowestPrice.toFixed(2)}{"€ "}
                        per person
                        </span>
                    ) : (
                        <span>Price not available</span>
                    )}
                    </p>
                  <a
                    href={`/safari/${safari.id}`}
                    className="block mt-4 bg-blue-600 text-white text-center py-2 rounded-md hover:bg-blue-700 transition"
                  >
                    View Details
                  </a>
                </div>
              </div>
            ))}
          </div>
        </section>
      );
      
};

export default SafariFeatured;
