import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Modal from './Modal'; // Jednoduchý Modal komponent
import Base from './Base';
import { useDropzone } from 'react-dropzone';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const ReviewsAdmin = () => {
  const [reviews, setReviews] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [newReviewData, setNewReviewData] = useState({
    rating: '',
    customer_name: '',
  });
  const [uploadedAvatar, setUploadedAvatar] = useState(null); // Avatar file
  const navigate = useNavigate();

  // Overenie, či je používateľ prihlásený
  const checkAuth = () => {
    if (!pb.authStore.isValid) {
      navigate('/admin/auth'); // Ak nie je prihlásený, presmeruj na prihlasovaciu stránku
    }
  };

  // Fetch all reviews
  const fetchReviews = async () => {
    try {
      const records = await pb.collection('reviews').getFullList({
        sort: '-updated', // Zoradenie podľa poslednej aktualizácie
      });
      setReviews(records);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };

  // Handle file upload using react-dropzone
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setUploadedAvatar(acceptedFiles[0]); // Nahraný súbor
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*', // Povoliť len obrázky
    multiple: false, // Len jeden súbor
  });

  // Create a new review
  const handleCreateReview = async () => {
    const { rating, customer_name } = newReviewData;
    if (!rating || !customer_name || !uploadedAvatar) {
      alert('Please fill in all fields and upload an avatar');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('rating', rating);
      formData.append('customer_name', customer_name);
      formData.append('avatar', uploadedAvatar);

      await pb.collection('reviews').create(formData);

      setIsCreateModalOpen(false);
      setNewReviewData({ rating: '', customer_name: '' });
      setUploadedAvatar(null);
      fetchReviews();
    } catch (error) {
      console.error('Error creating review:', error);
    }
  };

  // Delete a review
  const handleDeleteReview = async () => {
    if (!selectedReview) return;
    try {
      await pb.collection('reviews').delete(selectedReview.id);
      setIsDeleteModalOpen(false);
      setSelectedReview(null);
      fetchReviews();
    } catch (error) {
      console.error('Error deleting review:', error);
    }
  };

  useEffect(() => {
    checkAuth();
    fetchReviews();
  }, []);

  return (
    <Base>
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Reviews Management</h1>

        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-4"
          onClick={() => {
            setIsCreateModalOpen(true);
            setNewReviewData({ rating: '', customer_name: '' });
            setUploadedAvatar(null);
          }}
        >
          Create New Review
        </button>

        <ul className="space-y-2">
          {reviews.map((review) => (
            <li
              key={review.id}
              className="flex justify-between items-center p-2 border rounded-lg"
            >
              <div>
                <p className="font-semibold">
                    {review.customer_name} - {review.rating.length > 100 
                        ? `${review.rating.substring(0, 100)}...` 
                        : review.rating}
                </p>
              </div>
              <div className="flex space-x-2">
                <button
                  className="text-red-500"
                  onClick={() => {
                    setSelectedReview(review);
                    setIsDeleteModalOpen(true);
                  }}
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>

        {/* Create Review Modal */}
        {isCreateModalOpen && (
          <Modal onClose={() => setIsCreateModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">Create New Review</h2>
            <input
              type="text"
              placeholder="Customer Name"
              value={newReviewData.customer_name}
              onChange={(e) =>
                setNewReviewData({ ...newReviewData, customer_name: e.target.value })
              }
              className="border p-2 rounded-lg w-full mb-4"
            />
            <input
              type="text"
              placeholder="Rating (e.g., Excellent, Good, Fair)"
              onChange={(e) =>
                setNewReviewData({ ...newReviewData, rating: e.target.value })
              }
              className="border p-2 rounded-lg w-full mb-4"
            />

            {/* Drag and Drop Zone for Avatar */}
            <div
              {...getRootProps()}
              className={`border-dashed border-2 p-4 rounded-lg text-center ${
                isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
              }`}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the avatar here...</p>
              ) : (
                <p>Drag & drop avatar here, or click to select a file</p>
              )}
            </div>
            {uploadedAvatar && (
              <p className="mt-2 text-sm text-green-600">Selected file: {uploadedAvatar.name}</p>
            )}

            <div className="flex justify-end space-x-2 mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setIsCreateModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg z-10"
                onClick={handleCreateReview}
                role="button"
              >
                Create
              </button>
            </div>
          </Modal>
        )}

        {/* Delete Confirmation Modal */}
        {isDeleteModalOpen && (
          <Modal onClose={() => setIsDeleteModalOpen(false)}>
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p>
              Are you sure you want to delete the review by: {selectedReview?.customer_name}?
            </p>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                className="bg-gray-300 px-4 py-2 rounded-lg"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg"
                onClick={handleDeleteReview}
              >
                Delete
              </button>
            </div>
          </Modal>
        )}
      </div>
    </Base>
  );
};

export default ReviewsAdmin;
