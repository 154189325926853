import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PocketBase from "pocketbase";
import Base from "./Base";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const ManagePrices = () => {
  const { tourId } = useParams();
  const [cabins, setCabins] = useState([]);
  const [cabinsPrices, setCabinsPrices] = useState({});
  const [pricesMap, setPricesMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchCabinsAndPrices = async () => {
    try {
      setIsLoading(true);

      const tour = await pb.collection("tours").getOne(tourId, {
        expand: "ship",
        $autoCancel: false,
      });

      if (!tour || !tour.expand?.ship) {
        throw new Error("No ship associated with this tour.");
      }

      const cabins = await pb.collection("ship_cabins").getFullList({
        filter: `ship='${tour.expand.ship.id}'`,
        sort: "name",
        $autoCancel: false,
      });

      const pricesRecords = await pb.collection("cabins_prices").getFullList({
        filter: `tour='${tourId}'`,
        $autoCancel: false,
      });

      const pricesMap = {};
      pricesRecords.forEach((priceRecord) => {
        pricesMap[priceRecord.cabin] = { id: priceRecord.id, price: priceRecord.price };
      });

      const initialCabinsPrices = {};
      cabins.forEach((cabin) => {
        initialCabinsPrices[cabin.id] = pricesMap[cabin.id]?.price || "";
      });

      setCabins(cabins);
      setCabinsPrices(initialCabinsPrices);
      setPricesMap(pricesMap);
    } catch (error) {
      console.error("Error fetching cabins and prices:", error);
      alert(error.message || "An error occurred while fetching cabins and prices.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePriceChange = (cabinId, price) => {
    setCabinsPrices({
      ...cabinsPrices,
      [cabinId]: price,
    });
  };

  const handleSavePrice = async (cabinId) => {
    try {
      const price = cabinsPrices[cabinId];
      if (!price || isNaN(price)) {
        throw new Error(`Invalid price for cabin with ID: ${cabinId}`);
      }

      const priceRecord = pricesMap[cabinId];
      if (priceRecord) {
        await pb.collection("cabins_prices").update(priceRecord.id, {
          price: parseFloat(price),
        });
        alert("Price updated successfully!");
      } else {
        await pb.collection("cabins_prices").create({
          price: parseFloat(price),
          tour: tourId,
          cabin: cabinId,
        });
        alert("Price created successfully!");
      }
    } catch (error) {
      console.error("Error updating cabin price:", error);
      alert("An error occurred while updating the price.");
    }
  };

  useEffect(() => {
    fetchCabinsAndPrices();
  }, [tourId]);

  return (
    <Base>
      <div className="p-4">
        <button
          onClick={() => navigate(-1)}
          className="text-blue-500 underline mb-4"
        >
          Go Back
        </button>

        <h1 className="text-2xl font-bold mb-4">Manage Cabin Prices</h1>

        {isLoading ? (
          <p>Loading...</p>
        ) : cabins.length === 0 ? (
          <p>No cabins found for this tour.</p>
        ) : (
          <>
            <table className="table-auto w-full border-collapse border border-gray-200">
              <thead>
                <tr>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Cabin Name
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Price (€)
                  </th>
                  <th className="border border-gray-300 px-4 py-2 text-left">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {cabins.map((cabin) => (
                  <tr key={cabin.id}>
                    <td className="border border-gray-300 px-4 py-2">
                      {cabin.name}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <input
                        type="number"
                        value={cabinsPrices[cabin.id]}
                        onChange={(e) => handlePriceChange(cabin.id, e.target.value)}
                        className="border p-2 rounded-lg w-full"
                      />
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <button
                        onClick={() => handleSavePrice(cabin.id)}
                        role="button"
                        className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 z-10"
                      >
                        Save
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </Base>
  );
};

export default ManagePrices;
