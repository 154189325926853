import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const Base = ({ children }) => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profileType, setProfileType] = useState(null);

  useEffect(() => {
    // Check if the user is logged in and fetch profile_type
    if (pb.authStore.isValid) {
      setIsLoggedIn(true);

      const user = pb.authStore.model;
      setProfileType(user?.profile_type || null);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogout = () => {
    pb.authStore.clear();
    setIsLoggedIn(false);

    // Redirect based on profile_type
    if (profileType === "company") {
      navigate("/company/login");
    } else {
      navigate("/admin/login");
    }
  };

  const menuItems = [
    { name: "Home", path: "/" },
    { name: "Safari", path: "/safari" },
    { name: "Boats", path: "/boats" },
    { name: "Locations", path: "/locations" },
    {
      name: isLoggedIn ? "Dashboard" : "My Bookings",
      path: isLoggedIn
        ? profileType === "company"
          ? "/company/dashboard"
          : "/admin/dashboard"
        : "/my-bookings",
    },
  ];

  return (
    <div className="flex flex-col min-h-screen bg-gray-50 text-gray-800">
      {/* Navigation */}
      <nav className="bg-white shadow-md sticky top-0 z-50">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <a
            onClick={() => navigate("/")}
            className="text-2xl font-extrabold text-blue-600 cursor-pointer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              width="100"
              alt="Logo"
            />
          </a>
          <ul className="hidden md:flex space-x-6 font-medium">
            {menuItems.map((item) => (
              <li key={item.name}>
                <a
                  href={item.path}
                  className="text-gray-700 hover:text-blue-600"
                >
                  {item.name}
                </a>
              </li>
            ))}
            {isLoggedIn && (
              <li>
                <button
                  onClick={handleLogout}
                  className="text-gray-700 hover:text-blue-600"
                >
                  Logout
                </button>
              </li>
            )}
          </ul>
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden p-2 rounded border border-gray-400 hover:bg-gray-100"
          >
            <img
              src="https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/list.svg"
              alt="Menu"
              width="20"
            />
          </button>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden bg-white shadow-md">
            <ul className="flex flex-col space-y-4 py-4 px-6 font-medium">
              {menuItems.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.path}
                    className="text-gray-700 hover:text-blue-600"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
              {isLoggedIn && (
                <li>
                  <button
                    onClick={() => {
                      handleLogout();
                      setIsMobileMenuOpen(false);
                    }}
                    className="text-gray-700 hover:text-blue-600"
                  >
                    Logout
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
      </nav>

      {/* Main Content */}
      <main className="flex-grow">{children}</main>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-6">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 IconRedSea. All Rights Reserved.</p>
          <p className="mt-2">
            Contact us:{" "}
            <a
              href="mailto:info@iconredsea.com"
              className="underline text-blue-400"
            >
              info@iconredsea.com
            </a>
          </p>
          <p className="mt-2">
            Phone/WhatsApp:{" "}
            <a href="tel:+201017395587" className="underline text-blue-400">
              +20 1017395587
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Base;
