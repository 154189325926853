import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import PocketBase from "pocketbase";
import Base from "./Base";

const stripePromise = loadStripe("pk_live_51Pvm2URvBDI0YxIJVjNpLRqspGkRCpHWuJQRSIvOBpTjRXqKUSMhE0g5PwREfY5uNqSaLuX1iX6DUuceicpKNx5K00G3h2EdEQ");

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const PaymentForm = ({ reservation, bankDetails }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();
    const [paymentStatus, setPaymentStatus] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsProcessing(true);
        setPaymentStatus("");

        try {
            const amount = reservation.total_amount * 100; // Convert to cents

            if (reservation.payment_method === "card") {
                const { data } = await axios.post(`${process.env.REACT_APP_POCKETBASE_URL}/api/stripe/payment-intent`, {
                    amount: amount,
                    currency: "eur",
                });

                const clientSecret = data.clientSecret;
                const paymentIntentId = data.paymentIntentId;

                const cardElement = elements.getElement(CardElement);
                await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: cardElement,
                    },
                });

                const intervalId = setInterval(async () => {
                    try {
                        const statusResponse = await axios.get(
                            `${process.env.REACT_APP_POCKETBASE_URL}/api/stripe/payment-status/${paymentIntentId}`
                        );

                        const paymentStatus = statusResponse.data.paymentStatus;

                        if (paymentStatus === "paid") {
                            clearInterval(intervalId);
                            await pb.collection("reservations").update(reservation.id, { payment_status: "paid" });
                            setPaymentStatus("Payment was successful!");
                            setIsProcessing(false);
                            navigate("/payment-done");
                        } else if (paymentStatus === "failed") {
                            clearInterval(intervalId);
                            await pb.collection("reservations").update(reservation.id, { payment_status: "failed" });
                            setPaymentStatus("Payment failed. Please try again.");
                            setIsProcessing(false);
                        }
                    } catch (error) {
                        clearInterval(intervalId);
                        setPaymentStatus("Error checking payment status.");
                        setIsProcessing(false);
                    }
                }, 5000);
            }
        } catch (error) {
            setPaymentStatus("Payment failed. Please try again.");
            setIsProcessing(false);
        }
    };

    return (
        <div className="bg-white p-6 rounded shadow-md max-w-lg mx-auto text-gray-800">
            <h2 className="text-2xl font-bold mb-4">
                Pay <span className="text-blue-600">{reservation.total_amount} €</span>
            </h2>
            {reservation.payment_method === "bank_transfer" ? (
                <div>
                    <h3 className="text-xl font-semibold mb-2">Bank Transfer Details</h3>
                    {bankDetails ? (
                        <div className="space-y-2">
                            <p><strong>Account Name:</strong> {bankDetails.account_name}</p>
                            <p><strong>IBAN:</strong> {bankDetails.iban}</p>
                            <p><strong>SWIFT:</strong> {bankDetails.swift}</p>
                        </div>
                    ) : (
                        <p className="text-gray-500">Loading bank details...</p>
                    )}
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="space-y-4">
                    <CardElement
                        options={{ hidePostalCode: true }}
                        className="p-2 border rounded"
                    />
                    <button
                        type="submit"
                        disabled={!stripe || isProcessing}
                        className={`w-full px-4 py-2 rounded text-white font-semibold ${
                            isProcessing ? "bg-blue-400 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"
                        }`}
                    >
                        {isProcessing ? "Processing..." : "Pay Now"}
                    </button>
                </form>
            )}
            {paymentStatus && <p className="mt-4 text-gray-700">{paymentStatus}</p>}
        </div>
    );
};

const Payment = () => {
    const { reservationId } = useParams();
    const [reservation, setReservation] = useState(null);
    const [bankDetails, setBankDetails] = useState(null);

    useEffect(() => {
        const fetchReservation = async () => {
            try {
                const record = await pb.collection("reservations").getOne(reservationId);
                setReservation(record);
            } catch (error) {
                console.error("Error fetching reservation:", error);
            }
        };
        fetchReservation();
    }, [reservationId]);

    useEffect(() => {
        const fetchBankDetails = async () => {
            try {
                const record = await pb.collection("bank_details").getFirstListItem("", {
                    fields: "iban,swift,account_name",
                });
                setBankDetails(record);
            } catch (error) {
                console.error("Error fetching bank details:", error);
            }
        };

        fetchBankDetails();
    }, []);

    if (!reservation) {
        return (
            <Base>
                <p className="text-center text-gray-500 mt-10">Loading reservation...</p>
            </Base>
        );
    }

    return (
        <Base>
            <Elements stripe={stripePromise}>
                <PaymentForm reservation={reservation} bankDetails={bankDetails} />
            </Elements>
        </Base>
    );
};

export default Payment;
