import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import Base from "../Base";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const LoginCompany = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      // Login the user
      await pb.collection("users").authWithPassword(formData.email, formData.password);

      // Redirect to company dashboard
      navigate("/company/dashboard");
    } catch (err) {
      setError(err.message || "Invalid email or password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Base>
      <div className="container mx-auto px-4 py-10">
        <div className="max-w-md mx-auto p-6 bg-white shadow-lg rounded-md">
          <h1 className="text-2xl font-bold mb-6 text-center">Company Login</h1>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block mb-2 font-medium">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block mb-2 font-medium">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                className="p-2 border border-gray-300 rounded-md w-full"
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className={`p-2 w-full bg-blue-600 text-white rounded-md ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {loading ? "Logging in..." : "Login"}
            </button>
          </form>
          <p className="text-center mt-4 text-gray-600">
            Don't have an account?{" "}
            <a href="/company/register" className="text-blue-600 underline">
              Register here
            </a>
          </p>
        </div>
      </div>
    </Base>
  );
};

export default LoginCompany;
