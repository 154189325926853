import React, { useEffect, useState } from "react";
import PocketBase from "pocketbase";
import Base from "./Base";
import { useNavigate } from "react-router-dom"; // Import navigate

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const Boats = () => {
  const [boats, setBoats] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize navigate

  const truncateDescription = (description, maxLength = 100) => {
    if (description.length > maxLength) {
      return description.slice(0, maxLength) + "...";
    }
    return description;
  };

  const getImageUrl = (collectionId, recordId, fileName) => {
    return `${process.env.REACT_APP_POCKETBASE_URL}/api/files/${collectionId}/${recordId}/${fileName}`;
  };

  useEffect(() => {
    const fetchBoats = async () => {
      try {
        // Fetch all boats
        const boatsData = await pb.collection("ships").getFullList({
          sort: "-created", // Sort by newest created
        });

        setBoats(boatsData);
      } catch (error) {
        console.error("Error fetching boats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBoats();
  }, []);

  if (loading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  return (
    <Base>
      <div className="container mx-auto px-4 py-10">
        <h1 className="text-3xl font-bold text-center text-blue-600 mb-10">
          Available Boats
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {boats.map((boat) => (
            <div
              key={boat.id}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <img
                src={
                  boat.photos?.[0]
                    ? getImageUrl(boat.collectionId, boat.id, boat.photos[0])
                    : "https://via.placeholder.com/400"
                }
                alt={boat.name}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h2 className="text-xl font-bold text-gray-800">{boat.name}</h2>
                <p className="text-gray-600 text-sm">
                  {truncateDescription(boat.description)}
                </p>
                <p className="mt-2 text-blue-600 font-bold">
                  Size: {boat.size ? `${boat.size} m²` : "Not specified"}
                </p>
                <button
                  onClick={() => navigate(`/boats/${boat.id}`)}
                  className="mt-4 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                >
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Base>
  );
};

export default Boats;
